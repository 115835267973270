import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
// import Resetpassword from '../views/Resetpassword.vue'
// import Swap from '../views/Swap.vue'
// import Stakinghistories from '../views/Stakinghistories.vue'
// import Claimhistories from '../views/Claimhistories.vue'
import Listvoucher from '../views/Listvoucher.vue'
import NProgress from 'nprogress';
import { createRouter, createWebHashHistory } from 'vue-router'

const history = createWebHashHistory()
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'default'
    }
  },
  // {
  //   path: '/resetpassword',
  //   name: 'Resetpassword',
  //   component: Resetpassword,
  //   meta: {
  //     layout: 'default'
  //   }
  // },
  // {
  //   path: '/swap',
  //   name: 'Swap',
  //   component: Swap,
  //   meta: {
  //     layout: 'default'
  //   }
  // },
  {
    path: '/vouchers',
    name: 'Listvoucher',
    component: Listvoucher,
    meta: {
      layout: 'default'
    }
  },
  // {
  //   path: '/stakinghistories',
  //   name: 'Stakinghistories',
  //   component: Stakinghistories,
  //   meta: {
  //     layout: 'default'
  //   }
  // },
  // {
  //   path: '/claimhistories',
  //   name: 'Claimhistories',
  //   component: Claimhistories,
  //   meta: {
  //     layout: 'default'
  //   }
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  //   meta: {
  //     layout: 'default'
  //   }
  // }
]
const router = createRouter({
  linkActiveClass: 'active',
  history,
  routes
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export { router }
