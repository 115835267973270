import App from './App.vue'
import Vue, { createApp } from 'vue'
import Default from '@/layouts/default/Default.vue'

import { VueClipboard } from '@soerenmartius/vue3-clipboard'

import { createPinia } from 'pinia'
import { router } from './router'
import './axios'

const app = createApp(App)

app.component('LayoutDefault', Default)
// app.component('EasyDataTable', Vue3EasyDataTable);
app.use(createPinia())
app.use(router)
app.use(VueClipboard)

app.mount('#app')
