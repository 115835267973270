<template>
	<div class="loader" v-if="loading"></div>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div class="toast-container top-0 end-0 p-3">
			<div id="toastError" class="toast align-items-center text-bg-danger border-0" role="alert" aria-live="assertive"
				aria-atomic="true">
				<div class="d-flex">
					<div class="toast-body">
						{{ toast.body }}
					</div>
					<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close"></button>
				</div>
			</div>
		</div>
	</div>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div class="toast-container top-0 end-0 p-3">
			<div id="toastSuccess" class="toast align-items-center text-bg-success border-0" role="alert"
				aria-live="assertive" aria-atomic="true">
				<div class="d-flex">
					<div class="toast-body">
						{{ toast.body }}
					</div>
					<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close"></button>
				</div>
			</div>
		</div>
	</div>
	<div>
		<nav class="navbar navbar-light navbar-expand-md mb-0" v-if="!['/login', '/register'].includes($route.path)">
			<div class="container-fluid px-3 px-md-4">
				<a class="navbar-brand" href="#/"><img src="assets/img/logo-img.png" /><span>PT. KONAKAMI<br />DIGITAL INDONESIA</span></a>
				<button data-bs-toggle="collapse" class="navbar-toggler"  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="visually-hidden">Toggle navigation</span>
						<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
							<path d="M4 6H20M4 12H12M4 18H20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
						</svg>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav ms-auto">
						<li class="nav-item me-2 mb-2">
							<router-link v-if="isTronLinkActive" class="btn btn-outline-light" to="/vouchers">More Benerfits</router-link>
						</li>
						<li v-if="!isTronLinkActive" class="nav-item me-2 mb-2">
							<button class="btn btn-outline-light" :disabled="adapter.connected" @click="adapter.connect()">
								Connect to Wallet
							</button>
						</li>
						<li v-if="isTronLinkActive" class="nav-item me-2 mb-2">
							<a class="btn btn-outline-light" href="javascript:void()">
								{{ obfuscateString(account, 4, 4) }}
							</a>
						</li>
						<!-- <li v-if="isTronLinkActive" class="nav-item dropdown">
							<a class="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="outside" href="#"><img src="https://i.pravatar.cc/150?img=3" />{{ account }}</a>
							<div class="dropdown-menu dropdown-menu-end">
								<a @click.prevent="logout" class="dropdown-item"><i class="bi bi-box-arrow-right"></i>Logout</a>
							</div>
						</li> -->
					</ul>
				</div>
			</div>
		</nav>
		<div class="container-fluid p-3 p-md-4" v-if="isTronLinkActive">
			<div class="row gap-md-0 gap-3">
				<!-- Start: Sidebar -->
				<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
					<div class="sidebar-card bg-700">
						<!-- Start: Card PIN -->
						<div class="card p-4 p-md-3 bg-600 mb-md-4 mb-3">
							<div class="row">
								<div class="col-6">
									<h6><img class="me-2 float-start" src="assets/img/User%20Male.svg" />Benefit</h6>
								</div>
								<div class="col-6">
									<!-- <img class="me-2 float-end" style="width: 75%;" src="assets/img/powered-12.png" /> -->
								</div>
							</div>
						
						
							<div class="ps-2 border-start mb-0">
								<div class="row">
									<div class="col-12">
										<div class="d-grid gap-2 mt-1">
											<button class="btn btn-primary" @click="openModalWedeal()">More Benefits</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- End: Card PIN -->
					</div>
				</div>
				<!-- End: Sidebar -->
				<!-- Start: Content -->
				<div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
					<div class="main-content" >
						<div class="main-content_title">
							<h4>My Vouchers</h4>
						</div>
						<div class="main-content_body table-responsive ps-3 pe-3" v-if="listvoucher.length > 0">
							<!-- <EasyDataTable
							show-index
							:headers="voucherheaders"
							:items="voucheritems"
							@click-row="showVoucher"
						/> -->
						<div class="row">
							<div class="col-lg-3 col-md-6 col-sm-12 card-group" v-for="item in voucheritems" :key="item.id">
								<div class="card mb-3">
									<img src="../../public/assets/img/gift-voucher.png" class="card-img-top" alt="...">
									<div class="card-body">
										<button type="button" class="btn btn-success btn-sm mb-1 mt-1" v-if="item.diff_expired < 0"><i class="bi bi-check"></i>VOUCHER AKTIF</button>
										<button type="button" class="btn btn-secondary btn-sm mb-1 mt-1" v-if="item.diff_expired >= 0"><i class="bi bi-x"></i>VOUCHER EXPIRED</button>
										<h5>Voucher Digital Wedeals {{ item.vc_title }} - senilai Rp.  {{ item.wedeals_price.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', maximumFractionDigits: 0, minimumFractionDigits: 0 }) }}</h5>
										<p><i>* Berlaku 1 bulan setelah diterima</i></p>
										<div class="row">
											<div class="col-12 text-center">
												<span class="text-center">Kode Voucher :</span>
												<div class="alert alert-success" role="alert">
													{{ item.voucher_code }}
												</div>
											</div>
										</div>
										<div class="d-grid gap-2">
										<button class="btn btn-primary" type="button" @click="showVoucher(item)"> Detail </button>
										</div>
									</div>
								</div>
							</div>
						</div>
							
						</div>
						<div v-if="listvoucher.length <= 0" class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
							<div class="nodata p-4">
								<img src="assets/img/figure-nopayment.png" />
								<p class="lead fw-bold mb-1">No Data Yet!</p>
								<!-- <p class="small">Aww, its seems you still not find your favorite content yet. Let’s get one!
							</p> -->
								<a class="btn btn-primary" role="button" href="#">Explore Content</a>
							</div>
						</div>
					</div>
				</div>
				<!-- End: Content -->
			</div>
		</div>
		<div class="row" v-if="!isTronLinkActive">
			<div class="d-grid gap-2 col-6 mx-auto">
				<div class="main-content">
					<div class="main-content_title text-center">
						<h4>Wallet Not Connected</h4>
					</div>
					<div class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
						<div class="nodata p-4">
							<img src="assets/img/figure-nopayment.png" />
							<button class="btn btn-outline-light" role="button" :disabled="adapter.connected" @click="adapter.connect()">
								Connect to Wallet
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<modal-component :modelValue="showModalWedeal" title="Benefit Form">
		<!-- Modal Content -->
		<div class="modal-body">
			<div class="mb-3">
				<label for="">Your Address</label>
				<input type="text" class="form-control" v-model="w_address" readonly>
			</div>
			<div class="mb-3">
				<div class="row">
					<div class="col-6"></div>
					<div class="col-6"><img class="float-end" style="width: 75%;" src="assets/img/powered-12.png" /></div>
				</div>
			
			</div>
			<div class="mb-3">
				<label for="">Username</label>
				<input type="text" class="form-control user-input" v-model="w_username">
				<p v-if="wedealValid" class="text-small text-success">username valid</p>
				<label for="">Email</label>
				<input type="email" class="form-control" v-model="w_email">
				<p v-if="wedealValid" class="text-small text-success">email valid</p>
				<div class="mt-2 d-grid gap-2">
					<button type="button" class="btn btn-primary" @click="checkUsername()">Check Username</button>
				</div>
			
			</div>
			<div class="mb-3" v-if="wedealValid && wedealVcs != null">
			<!-- <div class="mb-3"> -->
				<div class="d-flex justify-content-between">
					<div class="text-white pe-3 pt-2"><strong>Burn Token</strong></div>
					<div class="text-white pe-3 pt-2"><strong>Benefits</strong></div>
				</div>
				<div v-for="item in wedealVcs" :key="item.id" class="card mb-2">
					<div class="d-flex justify-content-between card-header" :class="opwedeal[item.id]" @click="fopwedeal(item.id)">
						<div class="form-check">
							<input class="form-check-input hideradio" type="radio" :id="'wedealvc' + item.id" v-model="wedealvc" :value="item.id">
							<label class="form-check-label" :for="'wedealvc' + item.id">
								{{ item.vcdct_partner_price }} DCT
							</label>
							<!-- <div class="text-small" v-if="opwedeal[item.id]=='radio-selected'">
							<ul><li>Voucher hanya bisa digunakan didalam aplikasi WeDeals</li><li>Voucher tidak dapat di perjual belikan&nbsp;</li><li>Voucher tidak dapat ditukarkan dengan voucher yang lain</li><li>Voucher hanya bisa digunakan untuk pembelian Membership bukan produk lain</li><li>Voucher ini berlaku selama 30 Hari dari &nbsp;waktu diterbitkannya voucher ini.</li><li>Jika dalam 30 Hari voucher tidak di tukar maka voucher akan expired</li></ul>	
						</div> -->
						</div>
						<div class="text-end text-white pe-3 pt-2">{{ item.title }}</div>
					</div>
					<div class="card-body text-small text-white" v-if="opwedeal[item.id] == 'radio-selected'"><span v-html="item.tnc"></span></div>
				</div>
				<div class="d-grid gap-2">
					<button class="btn btn-primary" type="button" @click="fpilih()">Send Now</button>
				</div>
			
			</div>
		</div>
		<!-- Modal Footer -->
		<template #footer>
		<button class="btn btn-secondary" @click="showModalWedeal = false">Close</button>
		<!-- <button type="button" class="btn btn-primary" @click="checkUsername()">Check Username</button> -->
		</template>
	</modal-component>
	<modal-component :modelValue="showModalVoucher" title="">
		<!-- Modal Content -->
		<div class="modal-body">
			<div v-if="listVcSelected != null" class="card">
				<img src="../../public/assets/img/gift-voucher.png" class="card-img-top" alt="...">
				<div class="card-body">
					<button type="button" class="btn btn-success btn-sm mb-1 mt-1" v-if="listVcSelected.diff_expired < 0"><i class="bi bi-check"></i>VOUCHER AKTIF</button>
					<button type="button" class="btn btn-secondary btn-sm mb-1 mt-1" v-if="listVcSelected.diff_expired >= 0"><i class="bi bi-x"></i>VOUCHER EXPIRED</button>
					<h5 class="text-white">Voucher Digital Wedeals {{ listVcSelected.vc_title }} - senilai Rp.  {{ listVcSelected.wedeals_price.toLocaleString('id-ID', { style: 'currency', currency: 'IDR', maximumFractionDigits: 0, minimumFractionDigits: 0 }) }}</h5>
					<p class="text-white"><i>* Berlaku 1 bulan setelah diterima</i></p>
					<div class="row">
						<div class="col-12 text-center">
							<span class="text-center text-white">Kode Voucher :</span>
							<div class="alert alert-success" role="alert">
								{{ listVcSelected.voucher_code }}
							</div>
						</div>
					</div>
				
				</div>
			</div>
			<div v-if="listVcSelected != null" class="card mt-2">
				<div class="card-body">
					<div class="row">
						<div class="col-6">
							<div class="text-white">Berlaku Sampai</div>
							<div class="alert alert-secondary">{{ setDateTime(listVcSelected.expired_date) }}</div>
						</div>
						<div class="col-6">
							<div class="text-white">Platform</div>
							<div class="alert alert-secondary">Aplikasi Wedeals</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card mt-2">
				<div class="card-header text-white">
					Cara Menggunakan Voucher Digital
				</div>
				<div class="card-body">
					<div class="card">
						<div class="card-header">
							<a href="javascript:void(0)" @click="howtoCollapse(collapseStatus)" class="text-white">
								Web Aplikasi Wedeals <i v-if="collapseStatus" class="bi bi-caret-down-fill pull-right"></i><i v-if="!collapseStatus" class="bi bi-caret-left-fill pull-right"></i>
							</a>
						</div>
						<div class="card-body" :class="collapseClass" id="collapse-b1">
							<ol class="list-group list-group-flush">
								<li class="list-group-item">
									Buka Web Aplikasi <strong>WeDeals.io</strong>
								</li>
								<li class="list-group-item">
									Register & Login <strong>Akun WeDeals.io</strong>
								</li>
								<li class="list-group-item">
									Pilih Menu Profile <strong>Lalu Klik Basic Membership</strong>
								</li>
								<li class="list-group-item">
									Pilih tab <strong>Super Membership</strong>
								</li>
								<li class="list-group-item">
									Klik <strong>"Beli Membership Sekarang"</strong>, lalu pilih <strong>"Super Membership"</strong>
								</li>
								<li class="list-group-item">
									Setelah itu klik <strong>Konfirmasi Pembelian, dan Klik Syarat & Ketentuan Membership</strong>
								</li>
								<li class="list-group-item">
									Klik <strong>Bayar Sekarang</strong>, Lalu Pilih Voucher yang tersedia <strong>"Voucher Membership"</strong>
								</li>
								<li class="list-group-item">
									Terakhir Klik <strong>Bayar Sekarang</strong>, Tunggu Beberapa saat, Selamat Membership kamu sudah aktif
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		
		</div>
		<!-- Modal Footer -->
		<template #footer>
		<button class="btn btn-secondary" @click="showModalVoucher = false">Close</button>
		</template>
	</modal-component>
</template>

<script>
// @ is an alias to /src
import { TronLinkAdapter } from '@tronweb3/tronwallet-adapters';
import moment from 'moment-timezone'
import { Toast } from 'bootstrap/dist/js/bootstrap.esm.min.js'
// import TronWeb from 'tronweb'
import axios from 'axios'
import { defineRule } from 'vee-validate';
import { required, min, image } from '@vee-validate/rules';
import ModalComponent from '../components/Modal.vue'

defineRule('required', required);
defineRule('min', min);
defineRule('image', image);

const WalletReadyState = {
	NotFound: 'Not Found',
	Connecting: 'Connecting',
	Connected: 'Connected',
};

const setdecimal = 1000000000000000000;

var baseurl = '';
if (process.env.VUE_APP_NETWORK == 'mainnet') {
	baseurl = 'https://tronscan.org/#/transaction/';
} else {
	baseurl = 'https://nile.tronscan.org/#/transaction/';
}

export default {
	name: 'Listvoucher',
	components: {
		ModalComponent,
	},
	data() {
		return {
			readyState: WalletReadyState.NotFound,
			account: '',
			network: {},
			signedMessage: '',
			adapter: new TronLinkAdapter(),
			loading: false,
			tronWeb: null,
			isTronLinkActive: false,
			balance: 0,
			token: null,
			tokeninfo: {
				name: null,
				decimals: null,
				symbol: null,
			},
			tokenbalance: 0,
			tokenbalanceSun: 0,
			staking: null,
			stakinginfo: null,
			user: null,
			name: '',
			showModalVoucher: false,
			listvoucher: [],
			claimhistories: [],
			toast: {},
			w_address: '',
			w_username: '',
			w_email: '',
			wedealvc: null,
			opwedeal: [],
			listvoucher: [],
			voucherheaders: [],
			voucheritems: [],
			showModalWedeal: false,
			showModalVoucher: false,
			vcselected: null,
			wedealValid: false,
			wedealUser: {},
			wedealVcs: null,
			// wedealaddr: 'TSNJLPQfWgUGYeSwcK31dmKxWWFbNbjH5R', // tester
			wedealaddr: 'TMYZM6ZnAkp5xSqcyKuV2CtdAqZd3grBSV',
			listVcSelected: null,
			collapseStatus: false,
			collapseClass: 'collapse',
		}
	},
	// async created() {
	// 	// console.log('created')
	// 	await this.getTronweb()
	// },
	async mounted() {
		// console.log('token information', this.tokeninfo);
		this.account = this.adapter.address;
		this.w_address = this.account;
		this.readyState = this.adapter.state;
		if (this.readyState == 'Connected') {
			// console.log('ready state')
			// this.isTronLinkActive = true;
			await this.getTronweb()
			// console.log('isTronLinkActive', this.isTronLinkActive)
			if (this.isTronLinkActive) {
				await this.refreshAllData()
			}
		} else {
			this.isTronLinkActive = false;
		}
		this.adapter.on('connect', async () => {
			// console.log('connect')
			// this.isTronLinkActive = true;
			await this.getTronweb()
			// console.log('isTronLinkActive', this.isTronLinkActive)
			if (this.isTronLinkActive) {
				await this.refreshAllData()
			}
		});
		this.adapter.on('readyStateChanged', async (newState) => {
			// console.log('readyStateChanged')
			this.readyState = newState;
			// console.log('state.readyState: ', this.readyState)
			await this.getTronweb()
			// console.log('isTronLinkActive', this.isTronLinkActive)
			if (this.isTronLinkActive) {
				await this.refreshAllData()
			}
		});
		this.adapter.on('accountsChanged', async (data) => {
			// console.log('accountsChanged')
			this.account = data;
			this.w_address = this.account;
			// console.log('change state.account: ', this.account)
			await this.getTronweb()
			// console.log('isTronLinkActive', this.isTronLinkActive)
			if (this.isTronLinkActive) {
				await this.refreshAllData()
			}
		});
		this.adapter.on('chainChanged', async (data) => {
			// console.log('chainChanged')
			this.network = data;
			// console.log('chainChanged', this.network)
			await this.getTronweb()
			// console.log('isTronLinkActive', this.isTronLinkActive)
			if (this.isTronLinkActive) {
				await this.refreshAllData()
			}
		});
		this.adapter.on('disconnect', () => {
			// when disconnect from wallet
		});
	},
	methods: {
		// async getUser() {
		// 	try {
		// 		if (!localStorage.getItem('token')) {
		// 			this.$router.push('/login');
		// 		} else {
		// 			let getuser = await axios.get('/getuser', {
		// 				headers: {
		// 					Authorization: 'Bearer ' + localStorage.getItem('token')
		// 				}
		// 			});
		// 			if('data' in getuser) {
		// 				this.user = getuser.data.user;
		// 				// console.log('user', this.user)
		// 				console.log('this.user.addr', this.user.addr)
		// 				console.log('this.account', this.account)

		// 				if (this.user.addr != this.account) {
		// 					this.logout()
		// 				}
		// 			} else {
		// 				localStorage.removeItem('token');
		// 				this.$router.push('/login');
		// 			}
					
		// 		}
		// 	} catch (error) {
		// 		// console.log('getuser error', error)
		// 		console.log(' error catch', error.response.data.message)
		// 		// if(error.response.data.message=='Unauthenticated.') {
		// 			localStorage.removeItem('token');
		// 			this.$router.push('/login');
		// 		// }
		// 	}
			
		// },
		async refreshAllData() {
			if (this.isTronLinkActive) {
				await this.getToken()
				await this.getTokenInfo()
				await this.getAccountInfo()

				// await this.getUser()
				// // await this.getStageInfo()

				// await this.getListVoucher()
				await this.getListVoucher()
			}
		},
		async getTronweb() {
				if (window.tronLink.ready) {
					this.tronWeb = tronLink.tronWeb;
					this.isTronLinkActive = true
				} else {
					const res = await tronLink.request({ method: 'tron_requestAccounts' });
					if (res.code === 200) {
						this.tronWeb = tronLink.tronWeb;
						this.isTronLinkActive = true
					}
				}
		},
		async getToken() {
			try {
				this.token = await this.tronWeb.contract().at(process.env.VUE_APP_TOKENADDR)
				// console.log('token : ', this.token)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getTokenInfo() {
			try {
				// console.log('token : ', this.token)
				// let tokenname = await this.token.name().call()
				// this.tokeninfo.name = tokenname;
				// let tokensymbol = await this.token.symbol().call()
				// this.tokeninfo.symbol = tokensymbol;
				// let tokendecimals = await this.token.decimals().call()
				// this.tokeninfo.decimals = tokendecimals;
				this.tokeninfo.name = 'Degree Crypto Token';
				this.tokeninfo.symbol = 'DCT';
				this.tokeninfo.decimals = 18;
				// console.log(this.tokeninfo.name)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getAccountInfo() {
			try {
				this.account = await this.tronWeb.defaultAddress.base58
				let gettrxbalance = await this.tronWeb.trx.getBalance(this.account)
				this.balance = this.tronWeb.fromSun(gettrxbalance)
				// console.log(this.account)
				// console.log(this.balance)

				// token balance
				var gettokenbalance = await this.token.balanceOf(this.account).call()
				this.tokenbalanceSun = BigInt(gettokenbalance)
				// console.log(this.tokenbalanceSun)
				this.tokenbalance = this.fromSun(gettokenbalance)
				// console.log(this.tokenbalance)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async openModalWedeal() {
			this.loading = true;
			this.w_address = this.account;
			this.showModalWedeal = true

			this.vcselected = null
			this.wedealVcs = null
			this.wedealValid = false

			this.loading = false;
		},
		hideModalWedeal() {
			this.showModalWedeal = false
		},
		async openModalVoucher() {
			this.loading = true;
			this.showModalVoucher = true
			// console.log('this.listVcSelected : ', this.setDateTime(this.listVcSelected.expired_date))
			// console.log('diffDateTime: ', this.diffDateTime(this.listVcSelected.expired_date))

			this.loading = false;
		},
		hideModalVoucher() {
			this.showModalVoucher = false
		},
		async getListVoucher() {
			// this.loading = true;
			let getdata = await axios.post('/wedeals/listuservouchers-addr', {
				w_user_addr: this.account
			}, {});
			this.voucherheaders = [
				{ text: "TX ID", value: "txidsort" },
				{ text: " TX STATUS", value: "txstatus" },
				{ text: "VC CODE", value: "voucher_code" },
				{ text: "VC TITLE", value: "vc_title" },
				{ text: "VC PRICE", value: "vcdct_partner_price" },
				{ text: "EXPIRED DATE", value: "expired_date" },
				{ text: "CREATED", value: "created_at", sortable: true },
			];
			let res = getdata.data;
			if (res.status == 'success') {
				this.listvoucher = getdata.data.data;
				var gt = this.listvoucher;
				var vc_cd = ''
				for (let i = 0; i < gt.length; i++) {
					if(gt[i].voucher_code==null) {
						vc_cd = '--waiting--'
					} else {
						vc_cd = gt[i].voucher_code
					}
					this.voucheritems[i] = {
						no: i + 1,
						txidsort: this.obfuscateString(gt[i].txid, 6, 4),
						addr: gt[i].addr,
						txid: gt[i].txid,
						txstatus: gt[i].txstatus,
						wedeals_price: parseFloat(gt[i].wedeals_price),
						partner_price: parseFloat(gt[i].partner_price),
						voucher_code: vc_cd,
						vc_title: gt[i].vc_title,
						vcdct_partner_price: gt[i].vcdct_partner_price + ' ' + this.tokeninfo.symbol,
						expired_date: gt[i].expired_date,
						created_at: this.fromDate(gt[i].created_at),
						diff_expired: this.diffDateTime(gt[i].expired_date)
					}
				}
			}
			
			// this.items = this.listvoucher;
			// console.log('staking histories', this.listvoucher)
			// console.log('length: ' + this.listvoucher.length)
				
			
		},
		showRow(item) {
			// console.log('item', item)
			window.open(baseurl + item.txid, '_blank');
		},
		showVoucher(item) {
			console.log('item showVoucher', item)
			this.listVcSelected = item
			// this.listVcSelected.diff_expired = this.diffDateTime(this.listVcSelected.expired_date)
			console.log('this.listVcSelected : ', this.listVcSelected)
			// console.log('diffDateTime: ', this.diffDateTime(this.listVcSelected.expired_date))
			// window.open(baseurl + item.txid, '_blank');
			// open new modal window
			this.openModalVoucher()
		},
		howtoCollapse(sts) {
			this.collapseStatus = !sts;
			if (this.collapseStatus) {
				this.collapseClass = 'collapse show'
			} else {
				this.collapseClass = 'collapse'
			}
		},
		fopwedeal(value) {
			// console.log('opwedeal: ' , value);
			// if(value==1) { this.opwedeal1 = 'radio-selected'; this.opwedeal2 = ''; this.opwedeal3 = ''; this.opwedeal4 = '' }
			// if(value==2) { this.opwedeal1 = ''; this.opwedeal2 = 'radio-selected'; this.opwedeal3 = ''; this.opwedeal4 = '' }
			// if(value==3) { this.opwedeal1 = ''; this.opwedeal2 = ''; this.opwedeal3 = 'radio-selected'; this.opwedeal4 = '' }
			// if(value==4) { this.opwedeal1 = ''; this.opwedeal2 = ''; this.opwedeal3 = ''; this.opwedeal4 = 'radio-selected' }

			let vcs = this.wedealVcs
			for (let i = 0; i < vcs.length; i++) {
				if (vcs[i].id == value) {
					this.opwedeal[vcs[i].id] = 'radio-selected'
					this.vcselected = vcs[i]
					this.wedealvc = value
					// console.log('selected: ', vcs[i].id);
				} else {
					this.opwedeal[vcs[i].id] = ''
					// console.log('not selected: ', vcs[i].id);
				}
			}
		},
		async fpilih() {
			this.loading = true;
			// console.log('pilih : ', this.vcselected)
			if (this.tokenbalance < this.vcselected.vcdct_partner_price) {
				this.loading = false;
				this.showToastError('Insufficient dct balance');
			} else {
				// this.showToastSuccess('Request waiting!');
				// transfer token
				let amountTrf = this.toSun(this.vcselected.vcdct_partner_price)
				let isTrf = await this.token.transfer(this.wedealaddr, amountTrf).send();
				console.log('isTrf : ', isTrf)
				await setTimeout(async () => {
					let txStatus = await this.tronWeb.trx.getTransaction(isTrf);
					console.log('txStatus : ', txStatus)
					let sts = txStatus.ret[0].contractRet;
					if (sts == 'SUCCESS') {
						// save to database
						const response = await axios.post('/wedeals/savetxvoucher-addr', {
							w_user_addr: this.account,
							txid: isTrf,
							w_user_id: this.wedealUser.id,
							w_voucher_id: this.vcselected.id,
							dct_price: this.vcselected.dct_price,
							wedeals_price: this.vcselected.wedeals_price,
							partner_price: this.vcselected.partner_price,
							vcdct_wedeals_price: this.vcselected.vcdct_wedeals_price,
							vcdct_partner_price: this.vcselected.vcdct_partner_price,
							voucher_active_period: this.vcselected.voucher_active_period,
							vc_title: this.vcselected.title,
							vc_description: this.vcselected.description,
						}, {});
						let res = response.data;
						if (res.status == 'success') {
							this.getListVoucher()
							this.hideModalVoucher()
							this.hideModalWedeal()
							console.log('response success : ', res)
							this.showToastSuccess('Voucher payment success. Waiting for confirmation!')
						} else {
							this.wedealValid = false
							console.log('response error : ', res)
							this.showToastError('failed to get Voucher payment');
						}
						this.loading = false;

					} else {
						this.showToastError('Stake failed')
						this.loading = false;
					}
				}, 7000)

				// save hash
			}
		},
		async checkUsername() {
			// console.log('w_username: ' , this.w_username)
			// console.log('w_email: ', this.w_email)

			this.loading = true;
			console.log('checkUsername')
			try {
				const response = await axios.post('/wedeals/check-user-addr', {
					w_email: this.w_email,
					w_username: this.w_username
				}, {});
				let res = response.data;
				if (res.status == 'success') {
					console.log('response success : ', res)
					this.wedealValid = true
					this.wedealUser = res.data
					await this.getwdvcavailable()
				} else {
					this.wedealValid = false
					console.log('response error : ', res)
					// this.showToastError(res.message);
				}
				this.loading = false;
			} catch (error) {
				this.wedealValid = false
				console.log('check error', error.response.data.data.message);
				this.showToastError(error.response.data.data.message);
				this.loading = false;
			}
		},
		async getwdvcavailable() {
			this.loading = true;
			console.log('vc available')
			try {
				const response = await axios.post('/wedeals/available-addr', {
					w_limit: 10,
					w_page: 1
				}, {});
				let res = response.data;
				if (res.status == 'success') {
					console.log('response success : ', res)
					this.wedealVcs = res.data
				} else {
					console.log('response error : ', res)
					// this.showToastError(res.message);
				}
				this.loading = false;
			} catch (error) {
				this.wedealValid = false
				console.log('check error', error.response.data.data.message);
				this.showToastError(error.response.data.data.message);
				this.loading = false;
			}
		},
		async corporateInformation() {
			// console.log('w_username: ' , this.w_username)
			// console.log('w_email: ', this.w_email)

			this.loading = true;
			console.log('corporateInformation')
			try {
				const response = await axios.post('/wedeals/check-user', {}, {});
				let res = response.data;
				if (res.status == 'success') {
					console.log('response success : ', res)
				} else {
					console.log('response error : ', res)
					// this.showToastError(res.message);
				}
				this.loading = false;
			} catch (error) {
				console.log(error.message);
				this.showToastError(error.response.data.message);
				this.loading = false;
			}
		},
		obfuscateString(str, first, last) {
			// Ambil karakter pertama dan terakhir dari string
			const firstChars = str.slice(0, first);
			const lastChars = str.slice(-last);
			// Buat string tengah dengan karakter titik-titik
			const middleChars = '...';
			// Gabungkan semua string dan kembalikan hasilnya
			return firstChars + middleChars + lastChars;
		},
		
		async logout() {
			this.loading = true;
			console.log('logout')
			try {
				const response = await axios.post('/logout', {}, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				});
				let res = response.data;
				if (res.status) {
					localStorage.removeItem('token');
					this.$router.push('/login');
				} else {
					this.showToastError(res.message);
				}
				this.loading = false;
			} catch (error) {
				console.log(error.message);
				this.showToastError(error.response.data.message);
				this.loading = false;
			}

		},
		showToastSuccess(text) {
			this.toast.body = text
			const toastLiveExample = document.getElementById('toastSuccess')
			var toast = new Toast(toastLiveExample)
			toast.show()
		},
		showToastError(text) {
			this.toast.body = text
			const toastLiveExample = document.getElementById('toastError')
			var toast = new Toast(toastLiveExample)
			toast.show()
		},
		diffDate(date) {
			var now = moment();
			var time_utc = moment.unix(date).format();
			var user_zone = moment.tz.guess();
			var gettime = moment.tz(time_utc, user_zone).format();

			return now.diff(gettime)
		},
		fromDate(date) {
			moment.tz.setDefault("Asia/Jakarta");
			// var localdate = moment(date).tz('Asia/Jakarta').format();
			return moment(date).fromNow()
		},
		setDeadlineTime(date) {
			// console.log('date', date.toString())
			var time_utc = moment.unix(date).format();
			var user_zone = moment.tz.guess();

			var gettime = moment.tz(time_utc, user_zone).format("YYYY-MM-DD HH:mm:ss");
			// console.log('gettime: ' , gettime)
			return gettime
		},
		diffDateTime(date) {
			var now = moment();
			var gettime = moment(date).format();

			// return now.diff(gettime)
			return now.diff(gettime)
		},
		setDateTime(date) {
			var gettime = moment(date).format("YYYY-MM-DD HH:mm:ss");
			// console.log('gettime: ' , gettime)
			return gettime
		},
		fromDateTime(date) {
			moment.tz.setDefault("Asia/Jakarta");
			// var localdate = moment(date).tz('Asia/Jakarta').format();
			return moment.unix(date).fromNow()
		},
		fromSun(amount) {
			var amn = BigInt(amount)
			// console.log(amn)
			var calcAmount = Number(amn * 100000000n / BigInt(setdecimal)) / 100000000;
			// console.log(calcAmount)
			return calcAmount.toString();;
		},
		toSun(amount) {
			var calcAmount = BigInt(amount * setdecimal);
			return calcAmount.toString();;
		}
	}
}
</script>
