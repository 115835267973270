<template>
	<div class="loader" v-if="loading"></div>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div class="toast-container top-0 end-0 p-3">
			<div id="toastError" class="toast align-items-center text-bg-danger border-0" role="alert" aria-live="assertive"
				aria-atomic="true">
				<div class="d-flex">
					<div class="toast-body">
						{{ toast.body }}
					</div>
					<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close"></button>
				</div>
			</div>
		</div>
	</div>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div class="toast-container top-0 end-0 p-3">
			<div id="toastSuccess" class="toast align-items-center text-bg-success border-0" role="alert"
				aria-live="assertive" aria-atomic="true">
				<div class="d-flex">
					<div class="toast-body">
						{{ toast.body }}
					</div>
					<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close"></button>
				</div>
			</div>
		</div>
	</div>
	<nav class="navbar navbar-light navbar-expand-md">
		<div class="container-fluid px-4">
			<a class="navbar-brand" href="#">
				<img src="/assets/img/logo-img.png">
				<span>DEGREE CRYPTO<br />TOKEN</span>
			</a>
			<!-- <a class="btn btn-dark" role="button" href="#/login">
				<i class="la la-sign-in"></i>Login
			</a> -->
			<ul class="navbar-nav ms-auto">
				<li class="nav-item me-2 mb-2">
					<!-- <a class="btn btn-outline-light" href="#/swap">Swap V1 to V2</a> -->
                    <router-link v-if="isTronLinkActive" class="btn btn-outline-light" to="/vouchers">More Benefits</router-link>
				</li>
				<li class="nav-item me-2 mb-2">
					<a class="btn btn-dark" role="button" href="#/login">
						<i class="la la-sign-in"></i>Login
					</a>
				</li>
			</ul>
		</div>
	</nav>
	<div class="container">
		<div class="row justify-content-center align-items-center">
			<div class="col-11 col-sm-10 col-md-6 col-lg-4">
				<form class="form-auth" @submit.prevent="doRegister()" v-if="isTronLinkActive">
					<!-- Start: title -->
					<div class="title"><img src="assets/img/figure-login.png">
						<h4>Register</h4>
						<p>Create account to continue</p>
						<!-- <div>readyState: {{ readyState }}</div>
                            <div>current network: {{ network }}</div> -->
						<div v-if="errors != null">
							<ul class="list-unstyled">
								<li v-for="(value, key) in errors" :key="key" class="text-danger">
									{{ value[0] }}
								</li>
							</ul>
						</div>
						<div v-if="singleError != null">
							<p class="text-danger">{{ singleError }}</p>
						</div>
					</div><!-- End: title -->
					<div class="mb-4">
						<div class="input-lab">
							<span class="form-label">ADDRESS</span>
							<input class="form-control" type="text" placeholder="username/address" v-model="username" readonly>
						</div>
						<div class="input-lab">
							<span class="form-label">FULL NAME</span>
							<input class="form-control user-input" type="text" placeholder="Full Name" v-model="name" rules="required">
						</div>
						<!-- <div class="input-lab">
							<span class="form-label">PASSWORD</span>
							<input class="form-control" type="password" name="password" placeholder="password" v-model="password">
						</div>
						<div class="input-lab">
							<span class="form-label">PASSWORD CONFIRMATION</span>
							<input class="form-control" type="password" name="password_confirmation" placeholder="password" v-model="password_confirmation">
						</div> -->
						<div class="input-lab">
							<span class="form-label">Marketing Code (Optional)</span>
							<input class="form-control" type="text" placeholder="marketing code" v-model="marketingcode">
						</div>
						<div class="input-lab">
							<span class="form-label">EMAIL</span>
							<input class="form-control user-input" type="email" placeholder="Email" v-model="email" rules="required">
						</div>
						<div class="input-lab">
							<span class="form-label">No WhatsApp</span>
							<div class="input-group mb-3">
								<select class="form-control" v-model="selectedCountry">
										<option v-for="country in countries" :value="country.code" :key="country.code">
											{{ country.name }} (+{{ country.code }})
										</option>
									</select>
								<input type="text" class="form-control" v-model="nowa" aria-label="No Wa">
							</div>
							<!-- <input class="form-control user-input" type="text" placeholder="No WhatsApp" v-model="nowa" rules="required"> -->
							<!-- <div class="imput-group">
								<select class="form-select" v-model="selectedCountry">
									<option v-for="country in countries" :value="country.code" :key="country.code">
										{{ country.code }}
									</option>
								</select>
								<input class="form-control user-input" type="text" placeholder="No WhatsApp" v-model="nowa" :rules="required">
							</div> -->
						</div>
						<div class="input-lab">
							
						</div>
						<!-- <div class="input-lab">
							<span class="form-label">UPLOAD ID CARD</span>
							<input class="form-control form-control-lg" type="file" id="uploadId" ref="uploadId" accept="image/*" v-on:change="onChangeIDCard()" rules="required|image">
						</div>
						<div class="input-lab">
							<span class="form-label">UPLOAD PHOTO SELFIE</span>
							<input class="form-control form-control-lg" type="file" id="photoSelfie" ref="photoSelfie" v-on:change="onChangePhotoSelfie()" rules="required|image">
						</div> -->
						<button class="btn btn-primary w-100" type="submit">Create Account</button>
					</div>

					<small>By continuing, Community will receive ongoing access to the information that you share. Learn
						more about this sharing and the settings you have.</small>
					<hr class="w-25 m-auto my-4">
					<p class="text-center">Already have account? <router-link to="/login">Sign in here</router-link></p>
				</form>
			</div>
		</div>
        <div class="row" v-if="!isTronLinkActive">
            <div class="d-grid gap-2 col-6 mx-auto">
                <div class="main-content">
                    <div class="main-content_title text-center">
                        <h4>Wallet Not Connected</h4>
                    </div>
                    <div class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
                        <div class="nodata p-4">
                            <img src="assets/img/figure-nopayment.png" />
                            <button class="btn btn-outline-light" role="button" :disabled="adapter.connected" @click="adapter.connect()">
                                Connect to Wallet
                            </button>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
	<modal-component :modelValue="showModalRegister" title="Register Confirmation">
			<div class="modal-body">
				<div class="mb-3">
					<h3 class="text-danger">You don't input Ref Code</h3>
					<p class="text-white">Press Continue Register to continue registration without Ref Code!</p>
				</div>
			</div>
			<!-- Modal Footer -->
			<template #footer>
			<button class="btn btn-secondary" @click="showModalRegister = false">Cancel</button>
			<button type="button" class="btn btn-primary" @click="registerNow()">Continue Register</button>
			</template>
		</modal-component>
</template>

<script>
// import TronWeb from 'tronweb'
import { TronLinkAdapter } from '@tronweb3/tronwallet-adapters';
import axios from 'axios';
import { Toast } from 'bootstrap/dist/js/bootstrap.esm.min.js'

import ModalComponent from '../components/Modal.vue'

const WalletReadyState = {
	NotFound: 'Not Found',
	Connecting: 'Connecting',
	Connected: 'Connected',
};

export default {
	name: 'Register',
	components: {
		ModalComponent,
	},
	data() {
		return {
			readyState: WalletReadyState.NotFound,
			account: '',
			network: {},
			signedMessage: '',
			adapter: new TronLinkAdapter(),
			isTronLinkActive: false,
			account: '',
			name: '',
			username: '',
			password: '',
			password_confirmation: '',
			marketingcode: '',
			email: '',
			nowa: '',
			uploadId: null,
			photoSelfie: null,
			loading: false,
			toast: {},
			errors: null,
			singleError: null,
			countries: [
				{ name: 'Indonesia', code: '62' },
				{ name: 'Malaysia', code: '60' },
				{ name: 'Singapore', code: '65' },
				// tambahkan negara lainnya sesuai kebutuhan
			],
			selectedCountry: '62',
			recaptcha: '',
			siteKey: process.env.VUE_APP_SITEKEY,
			showModalRegister: false,
		}
	},
	mounted() {
		if (localStorage.getItem('token')) {
			this.$router.push('/');
		}
		this.readyState = this.adapter.state;
		if (this.readyState == 'Connected') {
			this.isTronLinkActive = true;
			this.getAccount();
		} else {
			this.isTronLinkActive = false;
		}
		this.account = this.adapter.address;
		this.adapter.on('connect', async () => {
			this.getAccount();
			this.isTronLinkActive = true;
		});
		this.adapter.on('readyStateChanged', (newState) => {
			this.readyState = newState;
			console.log('state.readyState: ', this.readyState)
		});
		this.adapter.on('accountsChanged', (data) => {
			this.account = data;
			console.log('change state.account: ', this.account)
		});
		this.adapter.on('chainChanged', (data) => {
			this.network = data;
			console.log('chainChanged', this.network)
		});
		this.adapter.on('disconnect', () => {
			// when disconnect from wallet
		});
	},
	beforeUnmount() {
		this.adapter.removeAllListeners();
	},
	methods: {
		onCaptchaVerified: function (recaptchaToken) {
			this.recaptcha = recaptchaToken
			this.validateCaptcha = true
		},
		onCaptchaExpired: function () {
			this.$refs.recaptcha.reset();
		},
		showToastSuccess(text) {
			this.toast.body = text;
			const toastLiveExample = document.getElementById('toastSuccess');
			var toast = new Toast(toastLiveExample);
			toast.show();
		},
		showToastError(text) {
			this.toast.body = text;
			const toastLiveExample = document.getElementById('toastError');
			var toast = new Toast(toastLiveExample);
			toast.show();
		},
		async getAccount() {
			this.account = this.adapter.address;
			this.username = this.account;
		},
		async doRegister() {
			console.log('upline code: ' + this.marketingcode);
			if(this.marketingcode == '' || this.marketingcode == null) {
				this.showModalRegister = true;
			} else {
				this.registerNow();
			}
		},
		async registerNow() {
			try {
				this.loading = true;
				const response = await axios.post('/register', {
					username: this.username,
					// password: this.password,
					// password_confirmation: this.password_confirmation,
					uplinecode: this.marketingcode,
					name: this.name,
					email: this.email,
					nowa: this.selectedCountry + this.nowa.replace(/^\+/, ''),
					'g-recaptcha-response': this.recaptcha
					// uploadId: this.uploadId,
					// photoSelfie: this.photoSelfie
				}
					// , {
					// 	headers: {
					// 		"Content-Type": "multipart/form-data"
					// 	}
					// }
				);
				let res = response.data;
				if (res.status) {
					this.resetErrorMsg()
					localStorage.setItem('token', res.token);
					this.showToastSuccess('Register success');
					this.$router.push('/');
					this.loading = false;
					this.showModalRegister = false;
				} else {
					this.resetErrorMsg()
					console.log('error registration')
					this.loading = false;
					this.showToastError('Register failed');
					this.showModalRegister = false;
				}
			} catch (error) {
				this.resetErrorMsg()
				// console.error('error catch :', error);
				this.showToastError(error.response.data.message);
				if ('errors' in error.response.data) {
					this.errors = error.response.data.errors;
				} else {
					this.singleError = error.response.data.message;
				}
				// console.log('error xxx: ', this.errors)
				this.loading = false;
				this.showModalRegister = false;
			} finally {
				this.loading = false;
				this.showModalRegister = false;
			}
		},
		onChangeIDCard() {
			this.uploadId = this.$refs.uploadId.files[0];
			console.log('upload id: ' + this.uploadId);
		},
		onChangePhotoSelfie() {
			this.photoSelfie = this.$refs.photoSelfie.files[0];
			console.log('photo selfie: ' + this.photoSelfie);
		},
		resetErrorMsg() {
			this.errors = null
			this.singleError = null
		}
	}
}
</script>

<style></style>