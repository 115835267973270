import axios from 'axios';
import NProgress from 'nprogress';

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.baseURL = 'https://minerstakingapi.degreecrypto.com/api';

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    NProgress.start();
    return config;
}, function (error) {
    NProgress.done();
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    NProgress.done();
    return response;
}, function (error) {
    NProgress.done();
    // Do something with response error
    console.log(error);
    return Promise.reject(error);
});