<template>
	<div class="loader" v-if="loading"></div>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div class="toast-container top-0 end-0 p-3">
			<div id="toastError" class="toast align-items-center text-bg-danger border-0" role="alert"
				aria-live="assertive" aria-atomic="true">
				<div class="d-flex">
					<div class="toast-body">
						{{ toast.body }}
					</div>
					<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close"></button>
				</div>
			</div>
		</div>
	</div>
	<div aria-live="polite" aria-atomic="true" class="position-relative">
		<div class="toast-container top-0 end-0 p-3">
			<div id="toastSuccess" class="toast align-items-center text-bg-success border-0" role="alert"
				aria-live="assertive" aria-atomic="true">
				<div class="d-flex">
					<div class="toast-body">
						{{ toast.body }}
					</div>
					<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
						aria-label="Close"></button>
				</div>
			</div>
		</div>
	</div>
	<div>
		<nav class="navbar navbar-light navbar-expand-md mb-0" v-if="!['/login', '/register'].includes($route.path)">
			<div class="container-fluid px-3 px-md-4">
				<a class="navbar-brand" href="javascript:void(0)" @click="showmenu = 'dashboard'"><img
						src="assets/img/logo-img.png" /><span>DEGREE CRYPTO<br />TOKEN</span></a>
				<button data-bs-toggle="collapse" class="navbar-toggler" data-bs-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="visually-hidden">Toggle navigation</span>
					<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none">
						<path d="M4 6H20M4 12H12M4 18H20" stroke="currentColor" stroke-width="2" stroke-linecap="round"
							stroke-linejoin="round"></path>
					</svg>
				</button>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav ms-auto">
						<li class="nav-item me-2 mb-2" v-if="user == null && isTronLinkActive">
							<!-- <a class="btn btn-outline-light" href="#/swap">Swap V1 to V2</a> -->
							<router-link class="btn btn-outline-light" to="/vouchers">Voucher Wedeals</router-link>
						</li>
						<li v-if="!isTronLinkActive" class="nav-item me-2 mb-2">
							<button class="btn btn-outline-light" :disabled="adapter.connected"
								@click="adapter.connect()">
								Connect to Wallet
							</button>
						</li>
						<li v-if="isTronLinkActive" class="nav-item dropdown">
							<a class="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown"
								data-bs-auto-close="outside" href="#"><img src="assets/img/logo-img.png" />{{
									obfuscateString(account, 4, 4) }}</a>
							<div class="dropdown-menu dropdown-menu-end">
								<!-- <router-link to="/logout" class="dropdown-item">Logout</router-link> -->
								<a @click.prevent="logout" class="dropdown-item"><i class="bi bi-box-arrow-right"></i>
									Logout</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<div class="container-fluid p-3 p-md-4" v-if="isTronLinkActive">
			<div class="row gap-md-0 gap-3">
				<!-- Start: Sidebar -->
				<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
					<div class="sidebar-card bg-700">
						<!-- Start: Card Profile -->
						<div class="side-profile mb-md-4 mb-3">
							<!-- <figure class="figure"> -->
							<!-- <img class="img-fluid figure-img" src="https://i.pravatar.cc/150?img=3" /> -->
							<!-- </figure> -->
							<!-- <a href="javascript:void(0)"> -->
							<span>{{ user.name }} (<a :href="gotourl + user.addr" target="_blank"
									rel="noopener noreferrer">{{ obfuscateString(user.addr, 4, 4) }}</a>)</span>
							<!-- <small>{{ user.userlevel }}</small> -->
							<!-- </a> -->
						</div>
						<!-- End: Card Profile -->
						<!-- Start: Card PIN -->
						<div class="card p-4 p-md-3 bg-600 mb-md-4 mb-3">
							<div class="row">
								<div class="col-6">
									<h6><img class="me-2 float-start" src="assets/img/User%20Male.svg" />Benefit</h6>
								</div>
								<div class="col-6">
									<!-- <img class="me-2 float-end" style="width: 75%;" src="assets/img/powered-12.png" /> -->
								</div>
							</div>


							<div class="ps-2 border-start mb-0">
								<div class="row">
									<div class="col-12">
										<div class="d-grid gap-2 mt-1">
											<button class="btn btn-primary" @click="openModalWedeal()">More
												Benefits</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- End: Card PIN -->
						<!-- Start: Card PIN -->
						<div class="card p-4 p-md-3 bg-600 mb-md-4 mb-3">
							<h6><img class="me-2" src="assets/img/User%20Male.svg" />Staking</h6>
							<div class="ps-2 border-start mb-0">
								<div class="row">
									<div class="col-12">
										<!-- <input type="number" class="form-control text-center" v-model="amounttostake"> -->
										<div class="d-grid gap-2 mt-1">
											<!-- <button class="btn btn-primary" @click="doStake()">Stake Now</button> -->
											<!-- <button class="btn btn-primary" @click="openModalStake()">Stake Now</button> -->
											<!-- <button class="btn btn-primary">Stake Now</button> -->
										</div>
									</div>
									<div class="col-12">
										<div class="d-grid gap-2">
											<!-- <button v-if="stakinginfo.amountStaked > 0" class="btn btn-success mt-2" @click="doClaimReward()">Claim Reward</button> -->
											<button v-if="stakinginfo != null && stakinginfo.amountStaked > 0"
												class="btn btn-success mt-2" @click="openModalReward()">Claim
												Reward</button>
											<button v-if="stakinginfo != null && stakinginfo.amountStaked <= 0"
												class="btn btn-success mt-2">Claim Reward</button>
										</div>
									</div>
									<div class="col-12">
										<div class="d-grid gap-2">
											<!-- <button v-if="stakinginfo.amountStaked>0" class="btn btn-warning mt-2" @click="doUnstake()">Unstake</button> -->
											<button v-if="stakinginfo != null && stakinginfo.amountStaked > 0"
												class="btn btn-warning mt-2"
												@click="openModalUnstake()">Unstake</button>
											<button v-if="stakinginfo != null && stakinginfo.amountStaked <= 0"
												class="btn btn-warning mt-2">Unstake</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- End: Card PIN -->
						<!-- Start: Card PIN -->
						<div class="card p-4 p-md-3 bg-600 mb-md-4 mb-3">
							<h6><img class="me-2" src="assets/img/User%20Male.svg" />Referral Code</h6>
							<div class="ps-2 border-start mb-0">
								<table class="table table-borderless tsmall">
									<tbody>
										<tr>
											<!-- <td>Code Ref</td> -->
											<td colspan="2" v-if="user != null">
												<div class="input-group mb-3">
													<input type="text" class="form-control" v-model="user.refcode"
														readonly>
													<button class="btn btn-outline-secondary" type="button"
														id="button-addon2" v-clipboard:copy="user.refcode"
														v-clipboard:success="onSuccess"
														v-clipboard:error="onError">Copy</button>
												</div>
												<!-- <input class='form-control' v-model="user.refcode" readonly />
												<div class="d-grid gap-2">
													<button class="btn btn-link btn-sm btn-block"
														v-clipboard:copy="user.refcode"
														v-clipboard:success="onSuccess"
														v-clipboard:error="onError"
													>
														Copy
													</button>
												</div> -->
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<!-- End: Card PIN -->
						<!-- Start: Card PIN -->
						<div class="card p-4 p-md-3 bg-600 mb-md-4 mb-3">
							<h6><img class="me-2" src="assets/img/User%20Male.svg" />Account Info</h6>
							<div class="ps-2 border-start mb-0">
								<table class="table table-borderless tsmall">
									<tbody>
										<tr>
											<td>TRX Balance</td>
											<td>{{ balance }} TRX</td>
										</tr>
										<tr>
											<td>Token Balance</td>
											<td v-if="tokenbalance != null">{{ tokenbalance }} {{ tokeninfo.symbol }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<!-- End: Card PIN -->
						<!-- Start: Card PIN -->
						<div class="card p-4 p-md-3 bg-600 mb-md-4 mb-3">
							<h6><img class="me-2" src="assets/img/Stack%20of%20Coins.png" />Token Info</h6>
							<div class="ps-2 border-start mb-0">
								<table class="table table-borderless tsmall">
									<tbody>
										<tr>
											<td>Name</td>
											<td v-if="tokeninfo.name == null"></td>
											<td v-if="tokeninfo.name != null">{{ tokeninfo.name }}</td>
										</tr>
										<tr>
											<td>Symbol</td>
											<td v-if="tokeninfo.symbol == null"></td>
											<td v-if="tokeninfo.symbol != null">{{ tokeninfo.symbol }}</td>
										</tr>
										<tr>
											<td>Decimal</td>
											<td v-if="tokeninfo.decimals == null"></td>
											<td v-if="tokeninfo.decimals != null">{{ tokeninfo.decimals }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<!-- End: Card PIN -->
						<!-- Start: Card PIN -->
						<div class="card p-4 p-md-3 bg-600 mb-md-4 mb-3">
							<h6><img class="me-2" src="assets/img/Layers.png" />Staking Info</h6>
							<div class="ps-2 border-start mb-0">
								<table class="table table-borderless tsmall">
									<tbody>
										<!-- <tr v-if="stakinginfo != null && stakinginfo.approved">
											<td>Address Status</td>
											<td>Approved</td>
										</tr> -->
										<tr>
											<td>Staking Status</td>
											<td v-if="stakinginfo.status == 0">No Staking Available</td>
											<td v-if="stakinginfo.status == 1">Staking Running</td>
											<td v-if="stakinginfo.status == 2">Staking Unstaked</td>
											<td v-if="stakinginfo.status == 3">Staking Burned</td>
										</tr>
										<tr>
											<td>Staking Running</td>
											<td>{{ (stakinginfo.amountStaked > 0) ? fromSun(stakinginfo.amountStaked) :
												0
												}} <span v-if="tokeninfo.symbol != null">{{ tokeninfo.symbol }}</span>
											</td>
										</tr>
										<tr>
											<td>Staking Pending</td>
											<td>{{ (stakinginfo.pendingStaking >
												0) ? fromSun(stakinginfo.pendingStaking) : 0 }} <span
													v-if="tokeninfo.symbol != null">{{ tokeninfo.symbol }}</span></td>
										</tr>
										<tr v-if="stakinginfo.minerCycle > 0 || stakinginfo.minerRoundCycle > 0">
											<td>Last Reward</td>
											<td>{{ (stakinginfo.lastRewardTime > 0) ?
												fromDateTime(stakinginfo.lastRewardTime) : 'No last reward' }}</td>
										</tr>
										<tr>
											<td>Next Reward</td>
											<td v-if="stakinginfo != null && diffDate(stakinginfo.nextrewardtime) <= 0">
												{{
													(stakinginfo.nextrewardtime > 0) ?
														fromDateTime(stakinginfo.nextrewardtime) : 'No next reward' }}</td>
											<td v-if="stakinginfo != null && diffDate(stakinginfo.nextrewardtime) > 0">-
											</td>
										</tr>
										<tr>
											<td>Claim Count</td>
											<td>{{ stakinginfo.minerCycle }}</td>
										</tr>
										<tr>
											<td>Claim Count Left</td>
											<td>{{ 11 - stakinginfo.minerCycle }}</td>
										</tr>
										<tr>
											<td>Miner Burn Date</td>
											<td>{{ (stakinginfo.minerBurnedTimestamp > 0) ?
												fromDateTime(stakinginfo.minerBurnedTimestamp) : 'No burned date' }}
											</td>
										</tr>
										<tr v-if="stakinginfo != null && stakinginfo.oldStaker">
											<td>Imported Account</td>
											<td>{{ stakinginfo.oldStaker }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<!-- End: Card PIN -->
						<div>
							<!-- Start: Verify -->
							<!-- <div class="card-stocking mb-md-4 mb-3 p-2 p-md-3">
								<img src="assets/img/Warranty.png" />
								<div>
									<p class="mb-0">Stocking Degree</p>
									<small>Earn rewards up to 30%.</small>
								</div>
							</div> -->
							<!-- End: Verify -->
							<ul class="nav flex-column sidenav">
								<li class="nav-item">
									<a class="nav-link" href="javascript:void(0)" @click="selectMenu('dashboard')"><img
											src="assets/img/Scroll.png" />Dashboard<i
											class="la la-angle-right arrow"></i></a>
								</li>
								<li class="nav-item">
									<a class="nav-link" href="javascript:void(0)"
										@click="selectMenu('stakinghistories')"><img
											src="assets/img/Scroll.png" />Staking History<i
											class="la la-angle-right arrow"></i></a>
								</li>
								<li class="nav-item">
									<a class="nav-link" href="javascript:void(0)"
										@click="selectMenu('claimhistories')"><img src="assets/img/Gift.png" />Claim
										Reward<i class="la la-angle-right arrow"></i></a>
								</li>
								<li class="nav-item">
									<a class="nav-link" href="javascript:void(0)"
										@click="selectMenu('my-vouchers')"><img src="assets/img/Gift.png" />My
										Vouchers<i class="la la-angle-right arrow"></i></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- End: Sidebar -->
				<!-- Start: Content -->
				<div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
					<div class="main-content" v-if="showmenu == 'dashboard'">
						<div class="main-content_title">
							<h4>Next Reward</h4>
						</div>
						<div class="main-content_body table-responsive ps-3 pe-3 text-white">
							<div v-if="stakinginfo != null && diffDate(stakinginfo.nextrewardtime) > 0"
								class="text-warning text-center">
								<h4>You have unclaimed rewards!</h4>
							</div>
							<Countdown v-if="stakinginfo.nextrewardtime > 0"
								:deadline="setDeadlineTime(stakinginfo.nextrewardtime)" />
							<div class="ps-2 border-start mb-0">
								<table class="table table-borderless tsmall">
									<tbody>
										<tr>
											<td>Next Estimate Claimable</td>
											<td v-if="stakinginfo.amountStaked != null && stakinginfo.amountStaked > 0">
												{{ nextreward.reward }}</td>
										</tr>
										<tr>
											<td>Current Reward Percentage</td>
											<td v-if="nowstage > 0">{{ getRewardPercent() }} %</td>
										</tr>
										<!-- <tr>
											<td>Decimal</td>
											<td v-if="tokeninfo.decimals == null"></td>
											<td v-if="tokeninfo.decimals != null">{{ tokeninfo.decimals }}</td>
										</tr> -->
									</tbody>
								</table>
							</div>
							<div class="row mt-3">
								<div class="col-lg-4">
									<div class="card text-bg-primary mb-3">
										<div class="card-body">
											<h5 class="card-title">Token Supply</h5>
											<!-- <h6 class="card-subtitle mb-2 text-body-secondary">{{ tokeninfo.maxsupply }}</h6> -->
											<ProgressBar :percent="dashboardinfo.supplypercent"
												:valuenow="dashboardinfo.supplyvaluenow"
												:valuemax="dashboardinfo.supplyvaluemax" color="success" />
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="card text-bg-success mb-3">
										<div class="card-body">
											<h5 class="card-title">Running Staking</h5>
											<!-- <h6 class="card-subtitle mb-2 text-body-secondary">Card subtitle</h6> -->
											<ProgressBar :percent="dashboardinfo.stakingpercent"
												:valuenow="dashboardinfo.stakingvaluenow"
												:valuemax="dashboardinfo.stakingvaluemax" color="warning" />
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="card text-bg-secondary mb-3">
										<div class="card-body">
											<h5 class="card-title">Pending Staking</h5>
											<!-- <h6 class="card-subtitle mb-2 text-body-secondary">Card subtitle</h6> -->
											<ProgressBar :percent="dashboardinfo.pendingpercent"
												:valuenow="dashboardinfo.pendingvaluenow"
												:valuemax="dashboardinfo.pendingvaluemax" color="info" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="main-content" v-if="showmenu == 'stakinghistories'">
						<div class="main-content_title">
							<h4>Staking History</h4>
						</div>
						<div class="main-content_body table-responsive ps-3 pe-3" v-if="stakinghistories.length > 0">
							<EasyDataTable show-index :headers="stakingheaders" :items="stakingitems"
								@click-row="showRow" />
						</div>
						<div v-if="stakinghistories.length <= 0"
							class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
							<div class="nodata p-4">
								<img src="assets/img/figure-nopayment.png" />
								<p class="lead fw-bold mb-1">No Data Yet!</p>
								<!-- <p class="small">Aww, its seems you still not find your favorite content yet. Let’s get one!
								</p> -->
								<a class="btn btn-primary" role="button" href="#">Explore Content</a>
							</div>
						</div>
					</div>
					<div class="main-content" v-if="showmenu == 'claimhistories'">
						<div class="main-content_title">
							<h4>My Vouchers</h4>
						</div>
						<div class="main-content_body table-responsive ps-3 pe-3" v-if="claimhistories.length > 0">
							<EasyDataTable show-index :headers="claimheaders" :items="claimitems"
								@click-row="showRow" />
						</div>
						<div v-if="claimhistories.length <= 0"
							class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
							<div class="nodata p-4">
								<img src="assets/img/figure-nopayment.png" />
								<p class="lead fw-bold mb-1">No Data Yet!</p>
								<!-- <p class="small">Aww, its seems you still not find your favorite content yet. Let’s get one!
								</p> -->
								<a class="btn btn-primary" role="button" href="#">Explore Content</a>
							</div>
						</div>
					</div>
					<div class="main-content" v-if="showmenu == 'my-vouchers'">
						<div class="main-content_title">
							<h4>My Vouchers</h4>
						</div>
						<div class="main-content_body table-responsive ps-3 pe-3" v-if="listvoucher.length > 0">
							<!-- <EasyDataTable
								show-index
								:headers="voucherheaders"
								:items="voucheritems"
								@click-row="showVoucher"
							/> -->
							<div class="row">
								<div class="col-lg-3 col-md-6 col-sm-12 card-group" v-for="item in voucheritems"
									:key="item.id">
									<div class="card">
										<img src="../../public/assets/img/gift-voucher.png" class="card-img-top"
											alt="...">
										<div class="card-body">
											<button type="button" class="btn btn-success btn-sm mb-1 mt-1"
												v-if="item.diff_expired < 0"><i class="bi bi-check"></i>VOUCHER
												AKTIF</button>
											<button type="button" class="btn btn-secondary btn-sm mb-1 mt-1"
												v-if="item.diff_expired >= 0"><i class="bi bi-x"></i>VOUCHER
												EXPIRED</button>
											<h5>Voucher Digital Wedeals {{ item.vc_title }} - senilai Rp. {{
												item.wedeals_price.toLocaleString('id-ID', {
													style: 'currency',
													currency: 'IDR', maximumFractionDigits: 0, minimumFractionDigits: 0
												})
											}}</h5>
											<p><i>* Berlaku 1 bulan setelah diterima</i></p>
											<div class="row">
												<div class="col-12 text-center">
													<span class="text-center">Kode Voucher :</span>
													<div class="alert alert-success" role="alert">
														{{ item.voucher_code }}
													</div>
												</div>
											</div>
											<div class="d-grid gap-2">
												<button class="btn btn-primary" type="button"
													@click="showVoucher(item)"> Detail </button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="listvoucher.length <= 0"
							class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
							<div class="nodata p-4">
								<img src="assets/img/figure-nopayment.png" />
								<p class="lead fw-bold mb-1">No Data Yet!</p>
								<!-- <p class="small">Aww, its seems you still not find your favorite content yet. Let’s get one!
								</p> -->
								<a class="btn btn-primary" role="button" href="#">Explore Content</a>
							</div>
						</div>
					</div>
				</div>
				<!-- End: Content -->
			</div>
		</div>
		<div class="row" v-if="!isTronLinkActive">
			<div class="d-grid gap-2 col-6 mx-auto">
				<div class="main-content">
					<div class="main-content_title text-center">
						<h4>Wallet Not Connected</h4>
					</div>
					<div class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
						<div class="nodata p-4">
							<img src="assets/img/figure-nopayment.png" />
							<button class="btn btn-outline-light" role="button" :disabled="adapter.connected"
								@click="adapter.connect()">
								Connect to Wallet
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<modal-component :modelValue="showModalWedeal" title="Benefit Form">
		<!-- Modal Content -->
		<div class="modal-body">
			<div class="mb-3">
				<label for="">Your Address</label>
				<input type="text" class="form-control" v-model="w_address" readonly>
			</div>
			<div class="mb-3">
				<div class="row">
					<div class="col-6"></div>
					<div class="col-6"><img class="float-end" style="width: 75%;" src="assets/img/powered-12.png" />
					</div>
				</div>

			</div>
			<div class="mb-3">
				<label for="">Username</label>
				<input type="text" class="form-control user-input" v-model="w_username">
				<p v-if="wedealValid" class="text-small text-success">username valid</p>
				<label for="">Email</label>
				<input type="email" class="form-control" v-model="w_email">
				<p v-if="wedealValid" class="text-small text-success">email valid</p>
				<div class="mt-2 d-grid gap-2">
					<button type="button" class="btn btn-primary" @click="checkUsername()">Check Username</button>
				</div>

			</div>
			<div class="mb-3" v-if="wedealValid && wedealVcs != null">
				<!-- <div class="mb-3"> -->
				<div class="d-flex justify-content-between">
					<div class="text-white pe-3 pt-2"><strong>Burn Token</strong></div>
					<div class="text-white pe-3 pt-2"><strong>Benefits</strong></div>
				</div>
				<div v-for="item in wedealVcs" :key="item.id" class="card mb-2">
					<div class="d-flex justify-content-between card-header" :class="opwedeal[item.id]"
						@click="fopwedeal(item.id)">
						<div class="form-check">
							<input class="form-check-input hideradio" type="radio" :id="'wedealvc' + item.id"
								v-model="wedealvc" :value="item.id">
							<label class="form-check-label" :for="'wedealvc' + item.id">
								{{ item.vcdct_partner_price }} DCT
							</label>
							<!-- <div class="text-small" v-if="opwedeal[item.id]=='radio-selected'">
							<ul><li>Voucher hanya bisa digunakan didalam aplikasi WeDeals</li><li>Voucher tidak dapat di perjual belikan&nbsp;</li><li>Voucher tidak dapat ditukarkan dengan voucher yang lain</li><li>Voucher hanya bisa digunakan untuk pembelian Membership bukan produk lain</li><li>Voucher ini berlaku selama 30 Hari dari &nbsp;waktu diterbitkannya voucher ini.</li><li>Jika dalam 30 Hari voucher tidak di tukar maka voucher akan expired</li></ul>	
						</div> -->
						</div>
						<div class="card-body text-end text-white pe-3 pt-2">{{ item.title }}</div>
					</div>
					<div class="text-small text-white" v-if="opwedeal[item.id] == 'radio-selected'"><span
							v-html="item.tnc"></span></div>
				</div>
				<!-- <div class="d-flex justify-content-between mb-2" :class="opwedeal2">
					<div class="form-check">
						<input class="form-check-input hideradio" type="radio" v-model="wedealvc" value="2" id="wedealvc2" @click="opwedeal(2)">
						<label class="form-check-label" for="wedealvc2">
							Degree Crypto Token
						</label>
						<div class="text-small">DCT</div>
					</div>
					<div class="text-white pe-3 pt-2">0.9 DCT</div>
				</div>
				<div class="d-flex justify-content-between mb-2" :class="opwedeal3">
					<div class="form-check">
						<input class="form-check-input hideradio" type="radio" v-model="wedealvc" value="3" id="wedealvc3" @click="opwedeal(3)">
						<label class="form-check-label" for="wedealvc3">
							Degree Crypto Token
						</label>
						<div class="text-small">DCT</div>
					</div>
					<div class="text-white pe-3 pt-2">1 DCT</div>
				</div>
				<div class="d-flex justify-content-between mb-2" :class="opwedeal4">
					<div class="form-check">
						<input class="form-check-input hideradio" type="radio" v-model="wedealvc" value="4" id="wedealvc4" @click="opwedeal(4)">
						<label class="form-check-label" for="wedealvc4">
							Degree Crypto Token
						</label>
						<div class="text-small">DCT</div>
					</div>
					<div class="text-white pe-3 pt-2">4 DCT</div>
				</div> -->
				<div class="d-grid gap-2">
					<button class="btn btn-primary" type="button" @click="fpilih()">Send Now</button>
				</div>

			</div>
		</div>
		<!-- Modal Footer -->
		<template #footer>
			<button class="btn btn-secondary" @click="showModalWedeal = false">Close</button>
			<!-- <button type="button" class="btn btn-primary" @click="checkUsername()">Check Username</button> -->
		</template>
	</modal-component>
	<modal-component :modelValue="showModalVoucher" title="">
		<!-- Modal Content -->
		<div class="modal-body">
			<div v-if="listVcSelected != null" class="card">
				<img src="../../public/assets/img/gift-voucher.png" class="card-img-top" alt="...">
				<div class="card-body">
					<button type="button" class="btn btn-success btn-sm mb-1 mt-1"
						v-if="listVcSelected.diff_expired < 0"><i class="bi bi-check"></i>VOUCHER AKTIF</button>
					<button type="button" class="btn btn-secondary btn-sm mb-1 mt-1"
						v-if="listVcSelected.diff_expired >= 0"><i class="bi bi-x"></i>VOUCHER EXPIRED</button>
					<h5 class="text-white">Voucher Digital Wedeals {{ listVcSelected.vc_title }} - senilai Rp. {{
						listVcSelected.wedeals_price.toLocaleString('id-ID', {
							style: 'currency', currency: 'IDR',
							maximumFractionDigits: 0, minimumFractionDigits: 0
						}) }}</h5>
					<p class="text-white"><i>* Berlaku 1 bulan setelah diterima</i></p>
					<div class="row">
						<div class="col-12 text-center">
							<span class="text-center text-white">Kode Voucher :</span>
							<div class="alert alert-success" role="alert">
								{{ listVcSelected.voucher_code }}
							</div>
						</div>
					</div>

				</div>
			</div>
			<div v-if="listVcSelected != null" class="card mt-2">
				<div class="card-body">
					<div class="row">
						<div class="col-6">
							<div class="text-white">Berlaku Sampai</div>
							<div class="alert alert-secondary">{{ setDateTime(listVcSelected.expired_date) }}</div>
						</div>
						<div class="col-6">
							<div class="text-white">Platform</div>
							<div class="alert alert-secondary">Aplikasi Wedeals</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div v-if="listVcSelected != null" class="card mt-2">
				<div class="card-title">Cara Menggunakan Voucher Digital</div>
				<div class="card-body">
					
				</div>
			</div> -->
			<div class="card mt-2">
				<div class="card-header text-white">
					Cara Menggunakan Voucher Digital
				</div>
				<div class="card-body">
					<div class="card">
						<div class="card-header">
							<!-- <div class="d-grid gap-2">
								<button type="button" class="btn btn-light" style="height: auto;" @click="howtoCollapse(collapseStatus)">
									Web Aplikasi Wedeals <i v-if="collapseStatus" class="bi bi-caret-down-fill"></i><i v-if="!collapseStatus" class="bi bi-caret-left-fill"></i>
								</button>
							</div> -->
							<a href="javascript:void(0)" @click="howtoCollapse(collapseStatus)" class="text-white">
								Web Aplikasi Wedeals <i v-if="collapseStatus"
									class="bi bi-caret-down-fill pull-right"></i><i v-if="!collapseStatus"
									class="bi bi-caret-left-fill pull-right"></i>
							</a>
						</div>
						<div class="card-body" :class="collapseClass" id="collapse-b1">
							<ol class="list-group list-group-flush">
								<li class="list-group-item">
									Buka Web Aplikasi <strong>WeDeals.io</strong>
								</li>
								<li class="list-group-item">
									Register & Login <strong>Akun WeDeals.io</strong>
								</li>
								<li class="list-group-item">
									Pilih Menu Profile <strong>Lalu Klik Basic Membership</strong>
								</li>
								<li class="list-group-item">
									Pilih tab <strong>Super Membership</strong>
								</li>
								<li class="list-group-item">
									Klik <strong>"Beli Membership Sekarang"</strong>, lalu pilih <strong>"Super
										Membership"</strong>
								</li>
								<li class="list-group-item">
									Setelah itu klik <strong>Konfirmasi Pembelian, dan Klik Syarat & Ketentuan
										Membership</strong>
								</li>
								<li class="list-group-item">
									Klik <strong>Bayar Sekarang</strong>, Lalu Pilih Voucher yang tersedia
									<strong>"Voucher
										Membership"</strong>
								</li>
								<li class="list-group-item">
									Terakhir Klik <strong>Bayar Sekarang</strong>, Tunggu Beberapa saat, Selamat
									Membership
									kamu sudah aktif
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>

		</div>
		<!-- Modal Footer -->
		<template #footer>
			<button class="btn btn-secondary" @click="showModalVoucher = false">Close</button>
		</template>
	</modal-component>
	<modal-component :modelValue="showModalStake" title="Staking Form">
		<!-- Modal Content -->
		<div class="modal-body">
			<div class="mb-3">
				<label for="">Amount to Stake</label>
				<input type="number" :max="maxtostake" class="form-control text-center" v-model="amounttostake">
			</div>
			<div class="mb-3">
				<strong>Stake conditions : </strong>
				<ol>
					<li>Reward can claim every 8 days</li>
					<li v-if="setfirstfee > 0">You will be charged a stake fee of {{ setfirstfee }} TRX</li>
					<li v-if="stakinginfo != null">{{ (stakinginfo.lockSetup > 0) ? ((fromSun(stakinginfo.lockSetup) *
						1) +
						(fromSun(stakinginfo.lockAmount) * 1)) : 4 }} {{ tokeninfo.symbol }} will be locked when UNSTAKE
					</li>
					<li>When you claim your reward, you will be charged a governance fee, so you must prepare sufficient
						fees in your wallet</li>
					<li>CRYPTOCURRENCY IS A HIGH VOLATILITY MARKET</li>
				</ol>
			</div>
		</div>
		<!-- Modal Footer -->
		<template #footer>
			<button class="btn btn-secondary" @click="showModalStake = false">Close</button>
			<!-- <button type="button" class="btn btn-primary" @click="doStake()">Stake Now</button> -->
			<button type="button" class="btn btn-primary" disabled>Stake Now</button>
		</template>
	</modal-component>
	<modal-component :modelValue="showModalReward" title="Claim Reward">
		<!-- Modal Content -->
		<div class="modal-body">
			<div class="mb-3 text-center text-white" v-if="unstakestatus.message == 'unstake'">
				<h4>Your miner status is UNSTAKE</h4>
				<h5>Please STAKE again to reactivate your miner</h5>
			</div>
			<div class="mb-3" v-if="!(unstakestatus.message == 'unstake' && unstakestatus.minerfee == 0)">
				<label for="">Claim Reward Estimation</label>
				<input type="number" class="form-control text-center" v-model="nextreward.reward" readonly>
			</div>
			<div class="mb-3" v-if="!(unstakestatus.message == 'unstake' && unstakestatus.minerfee == 0)">
				<strong>Reward Claim Information : </strong>
				<ul>
					<li>{{ setclaimfee }} TRX (Claim fee, you need prepared claim fee your wallet)</li>
					<li v-if="nextreward.reward > 0">{{ nextreward.reward - nextreward.fee - nextreward.tax }} {{
						tokeninfo.symbol }} (Amount will transfer to your address)</li>
					<li>{{ nextreward.fee }} {{ tokeninfo.symbol }} (Staking Fee 10% from your reward will automatic)
					</li>
					<li>{{ nextreward.tax }} {{ tokeninfo.symbol }} (Tax 1.1% from your reward)</li>
				</ul>
			</div>
		</div>
		<!-- Modal Footer -->
		<template #footer>
			<button class="btn btn-secondary" @click="showModalReward = false">Close</button>
			<button v-if="!(unstakestatus.message == 'unstake' && unstakestatus.minerfee == 0)" type="button"
				class="btn btn-primary" @click="doClaimReward()">Claim Reward Now</button>
			<a v-if="!(unstakestatus.message == 'unstake' && unstakestatus.minerfee == 0)"
				href="https://tronscan.org/#/contract/TZ7trrn98aT26UGTLUSieq2GRfwjfmn7Lq/code" target="_blank"
				class="btn btn-link mt-2">Go to MultiSig Url</a>
		</template>
	</modal-component>
	<modal-component :modelValue="showModalUnstake" title="Unstake Confirmation">
		<!-- Modal Content -->
		<div class="modal-body">
			<div class="mb-3" v-if="stakinginfo != null">
				<label for="">Amount to Unstake</label>
				<input type="number" class="form-control text-center" :value="fromSun(stakinginfo.amountStaked)"
					readonly>
			</div>
			<div class="mb-3">
				<strong>Unstake Information : </strong>
				<ul>
					<li>{{ setpinaltyfee }} TRX needed in your wallet as pinalty (or wait until 88 days from the first
						stake)</li>
					<li v-if="stakinginfo != null">{{ fromSun((stakinginfo.lockSetup * 1) + (stakinginfo.lockAmount *
						1)) }}
						{{ tokeninfo.symbol }} will be locked and will be burned if you don't stake again</li>
					<li>After doing UNSTAKE, if you do STAKE again, the total LOCK token will be multiple</li>
				</ul>
			</div>
		</div>
		<!-- Modal Footer -->
		<template #footer>
			<button class="btn btn-secondary" @click="showModalUnstake = false">Close</button>
			<button type="button" class="btn btn-primary" @click="doUnstake()">Unstake Now</button>
		</template>
	</modal-component>
</template>

<script>
// import { reactive, onMounted } from 'vue'
// @ is an alias to /src
import { TronLinkAdapter } from '@tronweb3/tronwallet-adapters';
import moment from 'moment-timezone'
import { Toast } from 'bootstrap/dist/js/bootstrap.esm.min.js'
// import TronWeb from 'tronweb'
import axios from 'axios'
import { defineRule } from 'vee-validate';
import { required, min, image } from '@vee-validate/rules';
import { Countdown } from 'vue3-flip-countdown'

import ModalComponent from '../components/Modal.vue'
import ProgressBar from '../components/ProgressBar.vue'
import 'bootstrap-icons/font/bootstrap-icons.css'
// import 'bootstrap/js/dist/collapse'

import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
// import { Header, Item, ClickRowArgument } from "vue3-easy-data-table";

defineRule('required', required);
defineRule('min', min);
defineRule('image', image);

const WalletReadyState = {
	NotFound: 'Not Found',
	Connecting: 'Connecting',
	Connected: 'Connected',
};

const maxStaking = Array(3);
maxStaking[1] = 10;
maxStaking[2] = 50;
maxStaking[3] = 200;

const rewardPercent = Array(7);
rewardPercent[1] = 3.75;
rewardPercent[2] = 3;
rewardPercent[3] = 2.5;
rewardPercent[4] = 2;
rewardPercent[5] = 1.5;
rewardPercent[6] = 1;
rewardPercent[7] = 0.5;

const setdecimal = 1000000000000000000;

var baseurl = '';
var addrurl = '';
if (process.env.VUE_APP_NETWORK == 'mainnet') {
	baseurl = 'https://tronscan.org/#/transaction/';
	addrurl = 'https://tronscan.org/#/address/';
} else {
	baseurl = 'https://nile.tronscan.org/#/transaction/';
	addrurl = 'https://nile.tronscan.org/#/address/';
}

export default {
	name: 'Home',
	components: {
		Countdown,
		ModalComponent,
		ProgressBar,
		EasyDataTable
	},
	data() {
		return {
			readyState: WalletReadyState.NotFound,
			account: '',
			network: {},
			signedMessage: '',
			adapter: new TronLinkAdapter(),
			loading: false,
			tronWeb: null,
			isTronLinkActive: false,
			balance: 0,
			balancesun: 0,
			token: null,
			tokeninfo: {
				name: null,
				decimals: null,
				symbol: null,
			},
			tokenbalance: 0,
			tokenbalanceSun: 0,
			staking: null,
			stakinginfo: null,
			amounttostake: 0,
			maxtostake: 0,
			setfirstfee: 0,
			setclaimfee: 0,
			setpinaltyfee: 0,
			nowstage: 0,
			user: null,
			username: '',
			name: '',
			uploadId: null,
			photoSelfie: null,
			stakinghistories: [],
			claimhistories: [],
			stakingheaders: [],
			stakingitems: [],
			claimheaders: [],
			claimitems: [],
			listvoucher: [],
			voucherheaders: [],
			voucheritems: [],
			toast: {},
			nextreward: {},
			showModalWedeal: false,
			showModalVoucher: false,
			showModalStake: false,
			showModalReward: false,
			showModalUnstake: false,
			showmenu: 'dashboard',
			gotourl: addrurl,
			dashboardinfo: {},
			w_address: '',
			w_username: '',
			w_email: '',
			wedealvc: null,
			opwedeal: [],
			// opwedeal1: '',
			// opwedeal2: '',
			// opwedeal3: '',
			// opwedeal4: '',
			vcselected: null,
			wedealValid: false,
			wedealUser: {},
			wedealVcs: null,
			// wedealaddr: 'TSNJLPQfWgUGYeSwcK31dmKxWWFbNbjH5R', // tester
			wedealaddr: 'TMYZM6ZnAkp5xSqcyKuV2CtdAqZd3grBSV',
			listVcSelected: null,
			collapseStatus: false,
			collapseClass: 'collapse',
			unstakestatus: {},
		}
	},
	// async created() {
	// 	// console.log('created')
	// 	await this.getTronweb()
	// },
	async mounted() {
		if (!localStorage.getItem('token')) {
			this.$router.push('/login');
		} else {
			// console.log('token information', this.tokeninfo);
			this.account = this.adapter.address;
			this.w_address = this.account;
			this.readyState = this.adapter.state;
			if (this.readyState == 'Connected') {
				this.loading = true;
				// console.log('ready state')
				// this.isTronLinkActive = true;
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
					await this.getMinerRoundCycle()
					this.loading = false;
				}
				this.loading = false;
			} else {
				this.isTronLinkActive = false;
			}
			this.adapter.on('connect', async () => {
				this.loading = true;
				// console.log('connect')
				// this.isTronLinkActive = true;
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
					await this.getMinerRoundCycle()
					this.loading = false;
				}
				this.loading = false;
			});
			this.adapter.on('readyStateChanged', async (newState) => {
				this.loading = true;
				// console.log('readyStateChanged')
				this.readyState = newState;
				// console.log('state.readyState: ', this.readyState)
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
					await this.getMinerRoundCycle()
					this.loading = false;
				}
				this.loading = false;
			});
			this.adapter.on('accountsChanged', async (data) => {
				this.loading = true;
				// console.log('accountsChanged')
				this.account = data;
				this.w_address = this.account;
				// console.log('change state.account: ', this.account)
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
					await this.getMinerRoundCycle()
					this.loading = false;
				}
				this.loading = false;
			});
			this.adapter.on('chainChanged', async (data) => {
				this.loading = true;
				// console.log('chainChanged')
				this.network = data;
				// console.log('chainChanged', this.network)
				await this.getTronweb()
				// console.log('isTronLinkActive', this.isTronLinkActive)
				if (this.isTronLinkActive) {
					await this.refreshAllData()
					await this.getMinerRoundCycle()
					this.loading = false;
				}
				this.loading = false;
			});
			this.adapter.on('disconnect', () => {
				// when disconnect from wallet
			});
		}
	},
	methods: {
		onSuccess() {
			this.showToastSuccess('Copy to clipboard')
		},
		onError() {
			this.showToastError('Failed to copy to clipboard')
		},
		async selectMenu(item) {
			this.showmenu = item;
			if (item == 'stakinghistories') {
				await this.getStakingHistory()
			} else if (item == 'claimhistories') {
				await this.getClaimHistory()
			} else if (item == 'my-vouchers') {
				await this.getListVoucher()
			}
		},
		async openModalWedeal() {
			this.loading = true;
			this.showModalWedeal = true

			this.vcselected = null
			this.wedealVcs = null
			this.wedealValid = false

			this.loading = false;
		},
		hideModalWedeal() {
			this.showModalWedeal = false
		},
		async openModalVoucher() {
			this.loading = true;
			this.showModalVoucher = true
			// console.log('this.listVcSelected : ', this.setDateTime(this.listVcSelected.expired_date))
			// console.log('diffDateTime: ', this.diffDateTime(this.listVcSelected.expired_date))

			this.loading = false;
		},
		hideModalVoucher() {
			this.showModalVoucher = false
		},
		async openModalStake() {
			this.loading = true;
			this.showModalStake = true
			var getCycle = await this.staking.minerCycle(this.account).call()
			// console.log('get cycle : ', getCycle)
			var firsttimefee = await this.staking.minerFirstTimeFee(this.account).call()
			// console.log('firsttimefee : ', firsttimefee)
			if (getCycle == 0 && (this.stakinginfo.amountStaked == 0 || firsttimefee > 0)) {
				// get first staking fee
				var firstfee = await this.staking.calcFirstStakingFee().call()
				this.setfirstfee = this.tronWeb.fromSun(firstfee);
				// console.log('firststaking fee : ', firstfee)
				this.loading = false;
			} else {
				this.setfirstfee = 0;
				// console.log('firststaking fee : ', firstfee)
				this.loading = false;
			}
		},
		hideModalStake() {
			this.showModalStake = false
		},
		async openModalReward() {
			this.loading = true;
			this.showModalReward = true
			await this.getNextRewardClaimable()
			// console.log('this.stakinginfo.oldStaker : ', this.stakinginfo.oldStaker)
			if (this.stakinginfo.status == 2 || this.stakinginfo.oldStaker) {
				this.setclaimfee = 0;
				this.loading = false;
			} else {
				// console.log('staking : ', this.staking)
				var claimFee = await this.staking.calcMinerClaimPayout().call()
				this.setclaimfee = this.tronWeb.fromSun(claimFee);
				this.loading = false;
			}
		},
		hideModalReward() {
			this.showModalReward = false
		},
		async openModalUnstake() {
			this.loading = true;
			this.showModalUnstake = true
			if (this.stakinginfo.status == 2 || this.stakinginfo.oldStaker) {
				this.setpinaltyfee = 0;
				this.loading = false;
			} else {
				var claimFee = await this.staking.calcResMinerClaimPayout().call()
				// console.log(' claimFee : ', claimFee)
				this.setpinaltyfee = this.tronWeb.fromSun(claimFee);
				this.loading = false;
			}
		},
		hideModalUnstake() {
			this.showModalUnstake = false
		},
		async getUser() {
			try {
				if (!localStorage.getItem('token')) {
					this.$router.push('/login');
				} else {
					let getuser = await axios.get('/getuser', {
						headers: {
							Authorization: 'Bearer ' + localStorage.getItem('token')
						}
					});
					if ('data' in getuser) {
						this.user = getuser.data.user;
						// console.log('user', this.user)
						console.log('this.user.addr', this.user.addr)
						console.log('this.account', this.account)
						this.w_address = this.user.addr

						if (this.user.addr != this.account) {
							this.logout()
						}
					} else {
						localStorage.removeItem('token');
						this.$router.push('/login');
					}

				}
			} catch (error) {
				// console.log('getuser error', error)
				console.log(' error catch', error.response.data.message)
				// if(error.response.data.message=='Unauthenticated.') {
				localStorage.removeItem('token');
				this.$router.push('/login');
				// }
			}

		},
		async refreshAllData() {
			if (this.isTronLinkActive) {
				this.loading = true;
				await this.sleep(1000).then(async () => {
					await this.getToken()
					// })
					// await this.sleep(1000).then(async () => {
					await this.getStaking()
					// console.log('sleep 1')
				})

				await this.sleep(1000).then(async () => {
					await this.getTokenInfo()
					// console.log('sleep 2')
				})

				await this.sleep(1000).then(async () => {
					await this.getAccountInfo()
					// console.log('sleep 2')
				})

				await this.sleep(1000).then(async () => {
					await this.getUser()
					// console.log('sleep 3')
				})

				await this.sleep(1000).then(async () => {
					await this.getStageInfo()
					// console.log('sleep 3')
				})

				await this.sleep(1000).then(async () => {
					await this.getStakingInfo()
					// console.log('sleep 4')
				})

				await this.sleep(1000).then(async () => {
					await this.getStakingInfo2()
					// console.log('sleep 4')
				})

				await this.sleep(1000).then(async () => {
					await this.getMinerType()
					// 	// console.log('sleep 4')
					// })

					// await this.sleep(1000).then(async () => {
					await this.getRestStaked()
					await this.getNextRewardClaimable()
					// console.log('sleep 5')
				})
				await this.getTokenCalcInfo()
				await this.getlaststakeunstake()

				this.loading = false;
			}
		},
		async sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms))
		},
		async getTronweb() {
			// if (window.tronWeb) {
			if (window.tronLink.ready) {
				this.tronWeb = tronLink.tronWeb;
				this.isTronLinkActive = true
			} else {
				const res = await tronLink.request({ method: 'tron_requestAccounts' });
				if (res.code === 200) {
					this.tronWeb = tronLink.tronWeb;
					this.isTronLinkActive = true
				}
			}
			// this.tronWeb = window.tronWeb
			// // console.log(this.tronWeb)
			// let isConnected = await this.tronWeb.isConnected()
			// // console.log(isConnected.fullNode)
			// if (isConnected.fullNode == true) {
			// 	this.isTronLinkActive = true
			// 	// console.log('called true')
			// 	// console.log(isConnected.fullNode)
			// } else {
			// 	this.isTronLinkActive = false
			// 	// console.log('called false')
			// 	// console.log(isConnected.fullNode)
			// }
			// } else {
			// 	this.isTronLinkActive = false
			// 	console.log('No active network')
			// }
		},
		async getToken() {
			try {
				this.token = await this.tronWeb.contract().at(process.env.VUE_APP_TOKENADDR)
				// console.log('token : ', this.token)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getTokenInfo() {
			try {
				// console.log('token : ', this.token)
				// let tokenname = await this.token.name().call()
				// this.tokeninfo.name = tokenname;
				// let tokensymbol = await this.token.symbol().call()
				// this.tokeninfo.symbol = tokensymbol;
				// let tokendecimals = await this.token.decimals().call()
				// this.tokeninfo.decimals = tokendecimals;
				this.tokeninfo.name = 'Degree Crypto Token';
				this.tokeninfo.symbol = 'DCT';
				this.tokeninfo.decimals = 18;

				let totalsupply = await this.token.totalSupply().call()
				this.tokeninfo.totalsupply = this.fromSun(totalsupply);
				// console.log('totalsupply : ', this.tokeninfo.totalsupply)
				let maxsupply = await this.token.maxSupply().call()
				this.tokeninfo.maxsupply = this.fromSun(maxsupply);
				// console.log('maxsupply : ', this.tokeninfo.maxsupply)
				// console.log(this.tokeninfo.name)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getAccountInfo() {
			try {
				this.account = await this.tronWeb.defaultAddress.base58
				let gettrxbalance = await this.tronWeb.trx.getBalance(this.account)
				this.balancesun = gettrxbalance;
				this.balance = this.tronWeb.fromSun(gettrxbalance)
				// console.log(this.account)
				// console.log(this.balance)

				// token balance
				var gettokenbalance = await this.token.balanceOf(this.account).call()
				this.tokenbalanceSun = BigInt(gettokenbalance)
				// console.log(this.tokenbalanceSun)
				this.tokenbalance = this.fromSun(gettokenbalance)
				// console.log(this.tokenbalance)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getStaking() {
			try {
				this.staking = await this.tronWeb.contract().at(process.env.VUE_APP_STAKINGADDR)
				// console.log(this.staking)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getStageInfo() {
			try {
				this.nowstage = await this.staking.nowStage().call()
				// console.log('now stage', this.nowstage)
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getStakingInfo() {
			try {

				this.stakinginfo = await this.staking.stakers(this.account).call()
				// console.log('this.stakinginfo : ', this.stakinginfo)

				// old staker
				this.stakinginfo.oldStaker = await this.staking.oldStaker(this.account).call()

				// pending staking
				this.stakinginfo.pendingStaking = await this.staking.pendingStaking(this.account).call()
				// last payout
				// this.stakinginfo.minerLastPayout = await this.staking.minerLastPayout(this.account).call()

				await this.sleep(1000).then(async () => {
					// next reward
					this.stakinginfo.nextrewardtime = await this.staking.getNextRewardTime(this.account).call()

					var diffd = this.diffDate(this.stakinginfo.nextrewardtime);
					// console.log('diffd : ', diffd)
					// console.log('this.stakinginfo.nextrewardtime : ', (this.stakinginfo.nextrewardtime).toString())
					var nexttime = moment.unix(this.stakinginfo.nextrewardtime).format()
					// console.log('nexttime : ', nexttime.toString())
					var nexthour = moment(nexttime).add(1, 'hours')
				})
				// console.log('nexthour : ', nexthour.toString())
				// this.stakinginfo.nextrewardtime = nexthour.unix()

				// console.log('this.stakinginfo.nextrewardtime : ', this.stakinginfo.nextrewardtime.toString())
				// console.log('this.nextrewardtime : ', this.stakinginfo.nextrewardtime)

				// // next claimable
				// this.stakinginfo.nextclaimable = await this.staking.getClaimableReward(this.account).call()
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getStakingInfo2() {
			try {
				// miner cycle
				this.stakinginfo.minerCycle = await this.staking.minerCycle(this.account).call()
				// await this.sleep(1000).then(async () => {
				let totalStaked = await this.staking.totalStaked().call()
				this.stakinginfo.totalStaked = this.fromSun(totalStaked)
				let totalPendingStaked = await this.staking.totalPendingStaked().call()
				this.stakinginfo.totalPendingStaked = this.fromSun(totalPendingStaked)
				// })

				// // next claimable
				// this.stakinginfo.nextclaimable = await this.staking.getClaimableReward(this.account).call()
			} catch (error) {
				console.log('error catch', error)
			}
		},
		async getMinerType() {
			// miner type
			this.stakinginfo.minerType = await this.staking.minerType(this.account).call()
			// console.log('this.minerType : ', this.stakinginfo.minerType)
		},
		async getMinerRoundCycle() {
			// miner minerRoundCycle
			this.stakinginfo.minerRoundCycle = await this.staking.minerRoundCycle(this.account).call()
		},
		async getRestStaked() {
			var stakingamount = (this.fromSun(this.stakinginfo.amountStaked) * 1) + (this.fromSun(this.stakinginfo.pendingStaking) * 1);
			var maxiStaking = maxStaking[this.stakinginfo.minerType];
			// console.log('minerType :', this.stakinginfo.minerType)
			// console.log('maxiStaking :', maxiStaking)
			var reststaking = maxiStaking - stakingamount
			// console.log('reststaking :', reststaking)
			if (this.stakinginfo.minerType == 0) {
				this.amounttostake = 10;
				this.maxtostake = 10;
			} else if (reststaking <= 0) {
				this.amounttostake = 0;
				this.maxtostake = 0;
			} else {
				this.amounttostake = reststaking;
				this.maxtostake = reststaking;
			}
		},
		async getTokenCalcInfo() {
			let maxsupply = parseFloat(this.tokeninfo.maxsupply)
			let totalsupply = parseFloat(this.tokeninfo.totalsupply)
			// console.log(maxsupply, totalsupply)
			let totalstaked = parseFloat(this.stakinginfo.totalStaked)
			let totalpendingstaked = parseFloat(this.stakinginfo.totalPendingStaked)
			let totalstaking = (totalstaked * 1) + (totalpendingstaked * 1)

			this.dashboardinfo.supplypercent = (totalsupply / maxsupply) * 100
			this.dashboardinfo.supplyvaluenow = totalsupply
			this.dashboardinfo.supplyvaluemax = maxsupply

			this.dashboardinfo.stakingpercent = (totalstaked / totalstaking) * 100
			this.dashboardinfo.stakingvaluenow = totalstaked
			this.dashboardinfo.stakingvaluemax = totalstaking

			this.dashboardinfo.pendingpercent = (totalpendingstaked / totalstaking) * 100
			this.dashboardinfo.pendingvaluenow = totalpendingstaked
			this.dashboardinfo.pendingvaluemax = totalstaking

			// console.log('dashboarinfo', this.dashboardinfo)

		},
		async doStake() {
			// console.log('do Stake')
			this.loading = true;
			this.showModalStake = false
			try {
				this.showToastError('Stake closed on this app')
				this.loading = false;
				// let amount = this.toSun(this.amounttostake)
				// let totstaked = this.amounttostake + Number(this.fromSun(this.stakinginfo.amountStaked)) + Number(this.fromSun(this.stakinginfo.pendingStaking))
				// console.log('tot staked', amount, totstaked)
				// if (amount <= 0) {
				// 	this.showToastError('Amount should greater than 0')
				// 	this.loading = false;
				// } else if (amount > this.tokenbalanceSun) {
				// 	this.showToastError('Insufficient balance')
				// 	this.loading = false;
				// }  else if (this.stakinginfo.minerType <= 0 && totstaked > maxStaking[1]) {
				// 	console.log('amount : ', this.amounttostake)
				// 	// console.log('max staking : ', maxtosun)
				// 	this.showToastError('Max Staking : ' + maxStaking[1] + ' ' + this.tokeninfo.symbol)
				// 	this.loading = false;
				// } else if (this.stakinginfo.minerType > 0 && totstaked > maxStaking[this.stakinginfo.minerType]) {
				// 	console.log('amount : ', this.amounttostake)
				// 	// console.log('max staking : ', maxtosun)
				// 	this.showToastError('Max Staking : ' + maxStaking[this.stakinginfo.minerType] + ' ' + this.tokeninfo.symbol)
				// 	this.loading = false;
				// } else {
				// 	var getCycle = await this.staking.minerCycle(this.account).call()
				// 	console.log('get cycle : ', getCycle)

				// 	if (!(this.stakinginfo.status == 2 && getCycle == 11) && this.stakinginfo.nextrewardtime < moment().unix() && this.stakinginfo.amountStaked > 0) {
				// 		console.log(this.stakinginfo.status, getCycle, this.stakinginfo.nextrewardtime, moment().unix(), this.stakinginfo.amountStaked)
				// 		this.showToastError('Please claim your reward before do staking!')
				// 		this.loading = false;
				// 	} else {
				// 		// check token approved
				// 		await this.token.approve(process.env.VUE_APP_STAKINGADDR, amount).send()
				// 		const tokenAllowance = await this.token.allowance(this.account, process.env.VUE_APP_STAKINGADDR).call()
				// 		// console.log('token allowance : ', BigInt(tokenAllowance))
				// 		if (amount == BigInt(tokenAllowance)) {
				// 			// Approve successful
				// 			this.showToastSuccess('Approve success')

				// 			var firsttimefee = await this.staking.minerFirstTimeFee(this.account).call()
				// 			console.log('firsttimefee : ', firsttimefee)
				// 			if (getCycle == 0 && (this.stakinginfo.amountStaked == 0 || firsttimefee > 0)) {
				// 				// get first staking fee
				// 				var firstfee = await this.staking.calcFirstStakingFee().call()
				// 				firstfee = firstfee.toString();
				// 				console.log('firststaking fee : ', firstfee)
				// 			} else {
				// 				firstfee = 0;
				// 				console.log('firststaking fee : ', firstfee)
				// 			}
				// 			// firstfee = 46980000;
				// 			// console.log('first staking fee : ', firstfee)
				// 			let isStaked = await this.staking.stake(amount).send({
				// 				callValue: firstfee,
				// 				feeLimit: 100000000,
				// 			})
				// 			await setTimeout(async () => {
				// 				let txStatus = await this.tronWeb.trx.getTransaction(isStaked);
				// 				let sts = txStatus.ret[0].contractRet;
				// 				if (sts == 'SUCCESS') {
				// 					// save to database
				// 					await axios.post('/dostake', {
				// 						txid: isStaked,
				// 					}, {
				// 						headers: {
				// 							Authorization: 'Bearer ' + localStorage.getItem('token')
				// 						}
				// 					});
				// 					this.showToastSuccess('Stake success')
				// 					await this.getAccountInfo()
				// 					await this.getStakingInfo()
				// 					await this.sleep(1000).then(async () => {
				// 						await this.getStakingHistory()
				// 						await this.getTokenCalcInfo()
				// 					})
				// 					this.loading = false;

				// 				} else {
				// 					this.showToastError('Stake failed')
				// 					this.loading = false;
				// 				}
				// 			}, 5000)
				// 			// console.log('isStaked', isStaked)
				// 			// let stakeWatch = await this.staking.Staked().watch(async (err, result) => {
				// 			// 	console.log('err x', err)
				// 			// 	console.log('result x', result)
				// 			// 	if (err) {
				// 			// 		console.error('event failed', err)
				// 			// 	} else {
				// 			// 		console.log('event success', result)
				// 			// 		await setTimeout(async () => {
				// 			// 			const events = await this.tronWeb.getEventByTransactionID(isStaked)
				// 			// 			// console.log('events', events)
				// 			// 			if (events.length > 0) {
				// 			// 				// Claim successful
				// 			// 				this.showToastSuccess('Stake success')
				// 			// 			} else {
				// 			// 				// No events found, claim may have failed
				// 			// 				this.showToastError('Stake failed')
				// 			// 			}
				// 			// 		}, 1000)
				// 			// 	}
				// 			// 	stakeWatch.stop()
				// 			// })
				// 		} else {
				// 			this.showToastError('Invalid token allowance')
				// 			this.loading = false;
				// 		}
				// 	}

				// }
			} catch (error) {
				this.loading = false;
				console.log('error catch', error)
			}
		},
		async doClaimReward() {
			this.loading = true;
			this.showModalReward = false
			// console.log('do Claim Reward')
			try {
				if (this.stakinginfo.amountStaked <= 0) {
					this.showToastError('Staking amount should greater than 0')
					this.loading = false;
				} else if (this.stakinginfo.nextrewardtime > moment().unix()) {
					this.showToastError('You have already claimed your reward')
					this.loading = false;
				} else if (this.stakinginfo.status == 2 && this.stakinginfo.minerCycle == 11) {
					this.showToastError('You have already claimed ALL your reward')
					this.loading = false;
				} else if (this.unstakestatus.message == 'unstake' && this.unstakestatus.minerfee == 0) {
					this.showToastError('You have already claimed ALL your reward after unstake')
					this.loading = false;
				} else {
					var claimFee = 0;
					this.stakinginfo.oldStaker = await this.staking.oldStaker(this.account).call()
					if (this.stakinginfo.status == 2 || this.stakinginfo.oldStaker) {
						claimFee = 0;
					} else {
						// console.log('staking : ', this.staking)
						claimFee = await this.staking.calcMinerClaimPayout().call()
						console.log(' claimFee : ', claimFee)
						claimFee = claimFee.toString();
					}
					console.log(' claimFee : ', claimFee)

					// call claim
					let isClaimed = await this.staking.claimReward().send({
						callValue: claimFee,
						feeLimit: 100000000
					})
					// console.log('isClaimed', isClaimed)
					await setTimeout(async () => {
						let txStatus = await this.tronWeb.trx.getTransaction(isClaimed);
						// console.log('txStatus', txStatus)
						let sts = txStatus.ret[0].contractRet;
						if (sts == 'SUCCESS') {
							await axios.post('/doclaimreward', {
								txid: isClaimed,
							}, {
								headers: {
									Authorization: 'Bearer ' + localStorage.getItem('token')
								}
							});
							this.showToastSuccess('Claim reward success')
							await this.getAccountInfo()
							await this.getStakingInfo()
							await this.sleep(1000).then(async () => {
								await this.getNextRewardClaimable()
								await this.getClaimHistory()
							})
							this.loading = false;
						} else {
							this.showToastError('Claim reward failed')
							this.loading = false;
						}
					}, 5000)
					// await setTimeout(async () => {
					// 	const events = await this.tronWeb.getEventByTransactionID(isClaimed)
					// 	console.log('events', events)
					// 	if (events.length > 0) {
					// 		// Claim successful
					// 		this.showToastSuccess('Claim reward success')
					// 	} else {
					// 		// No events found, claim may have failed
					// 		this.showToastError('Claim reward failed. length <= 0')
					// 	}
					// }, 5000)
				}
			} catch (error) {
				console.log(error)
				this.loading = false;
			}
		},
		async doUnstake() {
			this.loading = true;
			this.showModalUnstake = false
			// console.log('do Unstake')
			try {
				if (this.stakinginfo.amountStaked <= 0) {
					this.showToastError('Staking amount should greater than 0')
					this.loading = false;
				} else if (this.stakinginfo.status != 1) {
					this.showToastError('You not have active staking status')
					this.loading = false;
				} else {
					this.stakinginfo.oldStaker = await this.staking.oldStaker(this.account).call()
					if (this.stakinginfo.status == 2 || this.stakinginfo.oldStaker) {
						claimFee = 0;
					} else {
						var claimFee = await this.staking.calcResMinerClaimPayout().call()
						// console.log(' claimFee : ', claimFee)
						claimFee = claimFee.toString();
					}

					if (parseFloat(this.tronWeb.fromSun(claimFee)) > parseFloat(this.balance)) {
						console.log('claimfee sun : ', this.tronWeb.fromSun(claimFee), this.balance)
						this.showToastError('You dont have enough TRX balance to unstake')
						this.loading = false;
					} else {
						console.log(' claimFee : ', claimFee)
						// call claim
						let isClaimed = await this.staking.unstake().send({
							callValue: claimFee,
							feeLimit: 100000000
						})

						// console.log('isClaimed', isClaimed)
						await setTimeout(async () => {
							let txStatus = await this.tronWeb.trx.getTransaction(isClaimed);
							let sts = txStatus.ret[0].contractRet;
							if (sts == 'SUCCESS') {
								await axios.post('/dounstake', {
									txid: isClaimed,
								}, {
									headers: {
										Authorization: 'Bearer ' + localStorage.getItem('token')
									}
								});
								this.showToastSuccess('Unstake success')
								await this.getAccountInfo()
								await this.getStakingInfo()
								await this.sleep(1000).then(async () => {
									await this.getNextRewardClaimable()
									await this.getStakingHistory()
									await this.getTokenCalcInfo()
								})
								this.loading = false;
							} else {
								this.showToastError('Unstake failed')
								this.loading = false;
							}
						}, 5000)
						// let claimWatch = await this.staking.ClaimedStakedCapital().watch(async (err, result) => {
						// 	if (err) {
						// 		console.error('event failed', err)
						// 	} else {
						// 		console.log('event success', result)
						// 		await setTimeout(async () => {
						// 			const events = await this.tronWeb.getEventByTransactionID(isClaimed)
						// 			// console.log('events', events)
						// 			if (events.length > 0) {
						// 				// Claim successful
						// 				this.showToastSuccess('Claim capital success')
						// 			} else {
						// 				// No events found, claim may have failed
						// 				this.showToastError('Claim capital failed. length <= 0')
						// 			}
						// 		}, 5000)
						// 	}
						// 	claimWatch.stop()
						// })
					}


				}
			} catch (error) {
				console.log(error)
				this.loading = false;
			}

		},
		async getNextRewardClaimable() {
			var reward = 0;
			if (this.stakinginfo.amountStaked > 0) {
				reward = await (rewardPercent[this.nowstage] * this.fromSun(this.stakinginfo.amountStaked) / 100)
			}
			if (this.diffDate(this.stakinginfo.nextrewardtime) > 0) {
				// console.log('nextrewarded', reward)
				this.nextreward.reward = reward;
			} else {
				this.nextreward.reward = 0;
			}

			// console.log('this.nextreward.reward ', this.nextreward.reward )
			this.nextreward.fee = 10 * reward / 100;
			// console.log('this.nextreward.fee ', this.nextreward.fee)
			this.nextreward.tax = 1.1 * reward / 100;
			// console.log('this.nextreward.tax ', this.nextreward.tax)

		},
		getRewardPercent() {
			return rewardPercent[this.nowstage];
		},
		async getStakingHistory() {
			// this.loading = true;
			try {
				if (!localStorage.getItem('token')) {
					this.$router.push('/login');
				} else {
					let getdata = await axios.get('/getstakingtx', {
						headers: {
							Authorization: 'Bearer ' + localStorage.getItem('token')
						}
					});
					this.stakingheaders = [
						{ text: "TX ID", value: "txidsort" },
						{ text: "STATUS", value: "txstatus" },
						{ text: "ACTIVITY", value: "typex" },
						{ text: "AMOUNT", value: "amountStaked" },
						{ text: "CREATED", value: "created_at", sortable: true },
					];
					this.stakinghistories = getdata.data.data;
					var gt = this.stakinghistories;
					for (let i = 0; i < gt.length; i++) {
						this.stakingitems[i] = {
							no: i + 1,
							txidsort: this.obfuscateString(gt[i].txid, 6, 4),
							txid: gt[i].txid,
							txstatus: gt[i].txstatus,
							typex: gt[i].typex,
							amountStaked: parseFloat(gt[i].amountStaked) + ' ' + this.tokeninfo.symbol,
							created_at: this.fromDate(gt[i].created_at),
						}
					}
					// this.items = this.stakinghistories;
					// console.log('staking histories', this.stakinghistories)
					// console.log('length: ' + this.stakinghistories.length)
				}
			} catch (error) {
				console.log(' error catch', error.response.data.message)
				localStorage.removeItem('token');
				this.$router.push('/login');
				// }
			}
		},
		async getClaimHistory() {
			// this.loading = true;
			try {
				if (!localStorage.getItem('token')) {
					this.$router.push('/login');
				} else {
					let getdata = await axios.get('/getclaimtx', {
						headers: {
							Authorization: 'Bearer ' + localStorage.getItem('token')
						}
					});
					this.claimheaders = [
						{ text: "TX ID", value: "txidsort" },
						{ text: "STATUS", value: "txstatus" },
						{ text: "AMOUNT", value: "amountStaked" },
						{ text: "CREATED", value: "created_at", sortable: true },
					];
					this.claimhistories = getdata.data.data;
					var gt = this.claimhistories;
					for (let i = 0; i < gt.length; i++) {
						this.claimitems[i] = {
							no: i + 1,
							txidsort: this.obfuscateString(gt[i].txid, 6, 4),
							txid: gt[i].txid,
							txstatus: gt[i].txstatus,
							amountStaked: parseFloat(gt[i].claimamount) + ' ' + this.tokeninfo.symbol,
							created_at: this.fromDate(gt[i].created_at),
						}
					}
					// this.items = this.claimhistories;
					// console.log('claim histories', this.claimhistories)
					// console.log('length: ' + this.claimhistories.length)
				}
			} catch (error) {
				console.log(' error catch', error.response.data.message)
				localStorage.removeItem('token');
				this.$router.push('/login');
				// }
			}
		},
		async getListVoucher() {
			// this.loading = true;
			try {
				if (!localStorage.getItem('token')) {
					this.$router.push('/login');
				} else {
					let getdata = await axios.get('/wedeals/listuservouchers', {
						headers: {
							Authorization: 'Bearer ' + localStorage.getItem('token')
						}
					});
					this.voucherheaders = [
						{ text: "TX ID", value: "txidsort" },
						{ text: " TX STATUS", value: "txstatus" },
						{ text: "VC CODE", value: "voucher_code" },
						{ text: "VC TITLE", value: "vc_title" },
						{ text: "VC PRICE", value: "vcdct_partner_price" },
						{ text: "EXPIRED DATE", value: "expired_date" },
						{ text: "CREATED", value: "created_at", sortable: true },
					];
					this.listvoucher = getdata.data.data;
					var gt = this.listvoucher;
					for (let i = 0; i < gt.length; i++) {
						this.voucheritems[i] = {
							no: i + 1,
							txidsort: this.obfuscateString(gt[i].txid, 6, 4),
							addr: gt[i].addr,
							txid: gt[i].txid,
							txstatus: gt[i].txstatus,
							wedeals_price: parseFloat(gt[i].wedeals_price),
							partner_price: parseFloat(gt[i].partner_price),
							voucher_code: gt[i].voucher_code,
							vc_title: gt[i].vc_title,
							vcdct_partner_price: gt[i].vcdct_partner_price + ' ' + this.tokeninfo.symbol,
							expired_date: gt[i].expired_date,
							created_at: this.fromDate(gt[i].created_at),
							diff_expired: this.diffDateTime(gt[i].expired_date)
						}
					}
					// this.items = this.listvoucher;
					// console.log('staking histories', this.listvoucher)
					// console.log('length: ' + this.listvoucher.length)
				}
			} catch (error) {
				console.log(' error catch', error.response)
				localStorage.removeItem('token');
				this.$router.push('/login');
				// }
			}
		},
		async getlaststakeunstake() {
			// this.loading = true;
			try {
				if (!localStorage.getItem('token')) {
					this.$router.push('/login');
				} else {
					let getdata = await axios.get('/getlaststakeunstake', {
						headers: {
							Authorization: 'Bearer ' + localStorage.getItem('token')
						}
					});

					this.unstakestatus = getdata.data;
					// console.log('unstakestatus', this.unstakestatus)
				}
			} catch (error) {
				console.log(' error catch', error.response)
				localStorage.removeItem('token');
				this.$router.push('/login');
				// }
			}
		},
		showRow(item) {
			// console.log('item', item)
			window.open(baseurl + item.txid, '_blank');
		},
		showVoucher(item) {
			console.log('item showVoucher', item)
			this.listVcSelected = item
			// this.listVcSelected.diff_expired = this.diffDateTime(this.listVcSelected.expired_date)
			console.log('this.listVcSelected : ', this.listVcSelected)
			// console.log('diffDateTime: ', this.diffDateTime(this.listVcSelected.expired_date))
			// window.open(baseurl + item.txid, '_blank');
			// open new modal window
			this.openModalVoucher()
		},
		obfuscateString(str, first, last) {
			// Ambil karakter pertama dan terakhir dari string
			const firstChars = str.slice(0, first);
			const lastChars = str.slice(-last);
			// Buat string tengah dengan karakter titik-titik
			const middleChars = '...';
			// Gabungkan semua string dan kembalikan hasilnya
			return firstChars + middleChars + lastChars;
		},
		async logout() {
			this.loading = true;
			console.log('logout')
			try {
				const response = await axios.post('/logout', {}, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				});
				let res = response.data;
				if (res.status) {
					localStorage.removeItem('token');
					this.$router.push('/login');
				} else {
					this.showToastError(res.message);
				}
				this.loading = false;
			} catch (error) {
				console.log(error.message);
				this.showToastError(error.response.data.message);
				this.loading = false;
			}

		},
		onChangeIDCard() {
			this.uploadId = this.$refs.uploadId.files[0];
			console.log('upload id: ' + this.uploadId);
		},
		onChangePhotoSelfie() {
			this.photoSelfie = this.$refs.photoSelfie.files[0];
			console.log('photo selfie: ' + this.photoSelfie);
		},
		showToastSuccess(text) {
			this.toast.body = text
			const toastLiveExample = document.getElementById('toastSuccess')
			var toast = new Toast(toastLiveExample)
			toast.show()
		},
		showToastError(text) {
			this.toast.body = text
			const toastLiveExample = document.getElementById('toastError')
			var toast = new Toast(toastLiveExample)
			toast.show()
		},
		diffDate(date) {
			var now = moment();
			var time_utc = moment.unix(date).format();
			var user_zone = moment.tz.guess();
			var gettime = moment.tz(time_utc, user_zone).format();

			return now.diff(gettime)
		},
		fromDate(date) {
			moment.tz.setDefault("Asia/Jakarta");
			// var localdate = moment(date).tz('Asia/Jakarta').format();
			return moment(date).fromNow()
		},
		setDeadlineTime(date) {
			// console.log('date', date.toString())
			var time_utc = moment.unix(date).format();
			var user_zone = moment.tz.guess();

			var gettime = moment.tz(time_utc, user_zone).format("YYYY-MM-DD HH:mm:ss");
			// console.log('gettime: ' , gettime)
			return gettime
		},
		diffDateTime(date) {
			var now = moment();
			var gettime = moment(date).format();

			// return now.diff(gettime)
			return now.diff(gettime)
		},
		setDateTime(date) {
			var gettime = moment(date).format("YYYY-MM-DD HH:mm:ss");
			// console.log('gettime: ' , gettime)
			return gettime
		},
		fromDateTime(date) {
			moment.tz.setDefault("Asia/Jakarta");
			// var localdate = moment(date).tz('Asia/Jakarta').format();
			return moment.unix(date).fromNow()
		},
		fromSun(amount) {
			var amn = BigInt(amount)
			// console.log(amn)
			var calcAmount = Number(amn * 100000000n / BigInt(setdecimal)) / 100000000;
			// console.log(calcAmount)
			return calcAmount.toString();;
		},
		toSun(amount) {
			var calcAmount = BigInt(amount * setdecimal);
			return calcAmount.toString();;
		},
		howtoCollapse(sts) {
			this.collapseStatus = !sts;
			if (this.collapseStatus) {
				this.collapseClass = 'collapse show'
			} else {
				this.collapseClass = 'collapse'
			}
		},
		fopwedeal(value) {
			// console.log('opwedeal: ' , value);
			// if(value==1) { this.opwedeal1 = 'radio-selected'; this.opwedeal2 = ''; this.opwedeal3 = ''; this.opwedeal4 = '' }
			// if(value==2) { this.opwedeal1 = ''; this.opwedeal2 = 'radio-selected'; this.opwedeal3 = ''; this.opwedeal4 = '' }
			// if(value==3) { this.opwedeal1 = ''; this.opwedeal2 = ''; this.opwedeal3 = 'radio-selected'; this.opwedeal4 = '' }
			// if(value==4) { this.opwedeal1 = ''; this.opwedeal2 = ''; this.opwedeal3 = ''; this.opwedeal4 = 'radio-selected' }

			let vcs = this.wedealVcs
			for (let i = 0; i < vcs.length; i++) {
				if (vcs[i].id == value) {
					this.opwedeal[vcs[i].id] = 'radio-selected'
					this.vcselected = vcs[i]
					this.wedealvc = value
					// console.log('selected: ', vcs[i].id);
				} else {
					this.opwedeal[vcs[i].id] = ''
					// console.log('not selected: ', vcs[i].id);
				}
			}
		},
		async fpilih() {
			this.loading = true;
			// console.log('pilih : ', this.vcselected)
			if (this.tokenbalance < this.vcselected.vcdct_partner_price) {
				this.loading = false;
				this.showToastError('Insufficient dct balance');
			} else {
				// this.showToastSuccess('Request waiting!');
				// transfer token
				let amountTrf = this.toSun(this.vcselected.vcdct_partner_price)
				let isTrf = await this.token.transfer(this.wedealaddr, amountTrf).send();
				// console.log('isTrf : ', isTrf)
				await setTimeout(async () => {
					let txStatus = await this.tronWeb.trx.getTransaction(isTrf);
					// console.log('txStatus : ', txStatus)
					let sts = txStatus.ret[0].contractRet;
					if (sts == 'SUCCESS') {
						// save to database
						const response = await axios.post('/wedeals/savetxvoucher', {
							txid: isTrf,
							w_user_id: this.wedealUser.id,
							w_voucher_id: this.vcselected.id,
							dct_price: this.vcselected.dct_price,
							wedeals_price: this.vcselected.wedeals_price,
							partner_price: this.vcselected.partner_price,
							vcdct_wedeals_price: this.vcselected.vcdct_wedeals_price,
							vcdct_partner_price: this.vcselected.vcdct_partner_price,
							voucher_active_period: this.vcselected.voucher_active_period,
							vc_title: this.vcselected.title,
							vc_description: this.vcselected.description,
						}, {
							headers: {
								Authorization: 'Bearer ' + localStorage.getItem('token')
							}
						});
						let res = response.data;
						if (res.status == 'success') {
							this.getListVoucher()
							this.hideModalVoucher()
							this.hideModalWedeal()
							// console.log('response success : ', res)
							this.showToastSuccess('Voucher payment success. Waiting for confirmation!')
						} else {
							this.wedealValid = false
							// console.log('response error : ', res)
							this.showToastError('failed to get Voucher payment');
						}
						this.loading = false;

					} else {
						this.showToastError('Stake failed')
						this.loading = false;
					}
				}, 7000)

				// save hash
			}
		},
		async checkUsername() {
			// console.log('w_username: ' , this.w_username)
			// console.log('w_email: ', this.w_email)

			this.loading = true;
			console.log('checkUsername')
			try {
				const response = await axios.post('/wedeals/check-user', {
					w_email: this.w_email,
					w_username: this.w_username
				}, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				});
				let res = response.data;
				if (res.status == 'success') {
					// console.log('response success : ', res)
					this.wedealValid = true
					this.wedealUser = res.data
					await this.getwdvcavailable()
				} else {
					this.wedealValid = false
					console.log('response error : ', res)
					// this.showToastError(res.message);
				}
				this.loading = false;
			} catch (error) {
				this.wedealValid = false
				console.log('check error', error.response.data.data.message);
				this.showToastError(error.response.data.data.message);
				this.loading = false;
			}
		},
		async getwdvcavailable() {
			this.loading = true;
			console.log('vc available')
			try {
				const response = await axios.post('/wedeals/available', {
					w_limit: 10,
					w_page: 1
				}, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				});
				let res = response.data;
				if (res.status == 'success') {
					// console.log('response success : ', res)
					this.wedealVcs = res.data
				} else {
					console.log('response error : ', res)
					// this.showToastError(res.message);
				}
				this.loading = false;
			} catch (error) {
				this.wedealValid = false
				console.log('check error', error.response.data.data.message);
				this.showToastError(error.response.data.data.message);
				this.loading = false;
			}
		},
		async corporateInformation() {
			// console.log('w_username: ' , this.w_username)
			// console.log('w_email: ', this.w_email)

			this.loading = true;
			console.log('corporateInformation')
			try {
				const response = await axios.post('/wedeals/check-user', {}, {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token')
					}
				});
				let res = response.data;
				if (res.status == 'success') {
					console.log('response success : ', res)
				} else {
					console.log('response error : ', res)
					// this.showToastError(res.message);
				}
				this.loading = false;
			} catch (error) {
				console.log(error.message);
				this.showToastError(error.response.data.message);
				this.loading = false;
			}
		}
	}
}
</script>

<style></style>