<template>
    <div class="progress">
        <div class="progress-bar" :class="`bg-${color}`" role="progressbar" :style="{ width: `${percent}%` }"
            :aria-valuenow="valuenow" aria-valuemin="0" :aria-valuemax="valuemax">
            {{ valuenow }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        percent: {
            type: Number,
            default: 0,
            required: true,
            // validator: (value) => value >= 0 && value <= 100
        },
        valuenow: {
            type: Number,
            default: 0,
            required: true,
            // validator: (value) => value >= 0 && value <= 100
        },
        valuemax: {
            type: Number,
            default: 100,
            required: true,
            // validator: (value) => value >= 0 && value <= 100
        },
        color: {
            type: String,
            default: 'primary'
        }
    }
}
</script>