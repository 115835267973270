<template>
    <div class="loader" v-if="loading"></div>
    <div aria-live="polite" aria-atomic="true" class="position-relative">
        <div class="toast-container top-0 end-0 p-3">
            <div id="toastError" class="toast align-items-center text-bg-danger border-0" role="alert" aria-live="assertive"
                aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                        {{ toast.body }}
                    </div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                        aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
    <div aria-live="polite" aria-atomic="true" class="position-relative">
        <div class="toast-container top-0 end-0 p-3">
            <div id="toastSuccess" class="toast align-items-center text-bg-success border-0" role="alert"
                aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                    <div class="toast-body">
                        {{ toast.body }}
                    </div>
                    <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
                        aria-label="Close"></button>
                </div>
            </div>
        </div>
    </div>
    <nav class="navbar navbar-light navbar-expand-md">
        <div class="container-fluid px-4">
            <a class="navbar-brand" href="#">
                <img src="/assets/img/logo-img.png">
                <span>DEGREE CRYPTO<br />TOKEN</span>
            </a>
            <!-- <a class="btn btn-dark" role="button" href="#/login">
            <i class="la la-sign-in"></i>Login
        </a> -->
            <ul class="navbar-nav ms-auto">
                <li class="nav-item me-2 mb-2">
                    <!-- <a class="btn btn-outline-light" href="#/swap">Swap V1 to V2</a> -->
                    <router-link v-if="isTronLinkActive" class="btn btn-outline-light" to="/vouchers">More Benefits</router-link>
                </li>
                <li class="nav-item me-2 mb-2">
                    <a class="btn btn-dark" role="button" href="#/login">
                        <i class="la la-sign-in"></i>Login
                    </a>
                </li>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="row justify-content-center align-items-center" v-if="isTronLinkActive">
            <div class="col-11 col-sm-10 col-md-6 col-lg-4">
                <form class="form-auth" @submit.prevent="doLogin()">
                    <!-- Start: title -->
                    <div class="title"><img src="assets/img/figure-register.png">
                        <h4 class="mb-0">Login</h4>
                        <p>Please login to continue</p>
                        <div v-if="errors != null">
                            <ul class="list-unstyled">
                                <li v-for="(value, key) in errors" :key="key" class="text-danger">
                                    {{ value[0] }}
                                </li>
                            </ul>
                        </div>
                        <div v-if="singleError!=null">
                            <p class="text-danger">{{ singleError }}</p>
                        </div>
                    </div><!-- End: title -->
                    <div class="mb-4">
                        <div class="input-lab"><span class="form-label">ADDRESS</span><input class="form-control"
                                type="text" placeholder="username/address" v-model="username" readonly></div>
                        <!-- <div class="input-lab"><span class="form-label">PASSWORD</span><input class="form-control"
                                type="password" name="password" placeholder="password" v-model="password"></div> -->
                        <button class="btn btn-primary w-100" type="submit">Sign In</button>
                    </div>
                    <hr class="w-25 m-auto mb-4">
                    <p class="text-center">Don't have account? <router-link to="/register">Register here</router-link></p>
                    <!-- <p class="text-center">Forgot password? <router-link to="/resetpassword">Reset here</router-link></p> -->
                </form>
            </div>
        </div>
        <div class="row" v-if="!isTronLinkActive">
            <div class="d-grid gap-2 col-6 mx-auto">
                <div class="main-content">
                    <div class="main-content_title text-center">
                        <h4>Wallet Not Connected</h4>
                    </div>
                    <div class="main-content_body d-flex flex-grow-1 align-items-center justify-content-center">
                        <div class="nodata p-4">
                            <img src="assets/img/figure-nopayment.png" />
                            <button class="btn btn-outline-light" role="button" :disabled="adapter.connected" @click="adapter.connect()">
                                Connect to Wallet
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import TronWeb from 'tronweb'
import { TronLinkAdapter } from '@tronweb3/tronwallet-adapters';
import axios from 'axios';
import { Toast } from 'bootstrap/dist/js/bootstrap.esm.min.js'

const WalletReadyState = {
    NotFound: 'Not Found',
    Connecting: 'Connecting',
    Connected: 'Connected',
};

export default {
    name: 'Login',
    components: {
    },
    data() {
        return {
            readyState: WalletReadyState.NotFound,
            account: '',
            network: {},
            signedMessage: '',
            adapter: new TronLinkAdapter(),
            isTronLinkActive: false,
            account: '',
            username: '',
            password: '',
            loading: false,
            toast: {},
            errors: null,
            singleError: null,
        }
    },
    mounted() {
        if (localStorage.getItem('token')) {
            this.$router.push('/');
        }
        this.readyState = this.adapter.state;
        if (this.readyState == 'Connected') {
            this.isTronLinkActive = true;
            this.getAccount();
        } else {
            this.isTronLinkActive = false;
        }
        this.account = this.adapter.address;
        this.adapter.on('connect', async () => {
            this.getAccount();
            this.isTronLinkActive = true;
        });
        this.adapter.on('readyStateChanged', (newState) => {
            this.readyState = newState;
            console.log('state.readyState: ', this.readyState)
        });
        this.adapter.on('accountsChanged', (data) => {
            this.account = data;
            this.getAccount()
            console.log('change state.account: ', this.account)
        });
        this.adapter.on('chainChanged', (data) => {
            this.network = data;
            console.log('chainChanged', this.network)
        });
        this.adapter.on('disconnect', () => {
            // when disconnect from wallet
        });
    },
    beforeUnmount() {
        this.adapter.removeAllListeners();
    },
    methods: {
        showToastSuccess(text) {
            this.toast.body = text;
            const toastLiveExample = document.getElementById('toastSuccess');
            var toast = new Toast(toastLiveExample);
            toast.show();
        },
        showToastError(text) {
            this.toast.body = text;
            const toastLiveExample = document.getElementById('toastError');
            var toast = new Toast(toastLiveExample);
            toast.show();
        },
        async getAccount() {
            this.account = this.adapter.address;
            this.username = this.account;
        },
        async doLogin() {
            try {
                this.loading = true;
                const response = await axios.post('/login', {
                    username: this.username,
                    // password: this.password,
                });
                let res = response.data;
                if (res.status) {
                    localStorage.setItem('token', res.token);
                    this.showToastSuccess('Login success');
                    this.$router.push('/');
                } else {
                    console.log('error login: ' + res.status)
                    this.loading = false;
                    this.showToastError('Login failed');
                }
            } catch (error) {
                // console.error('error catch :', error);
                this.showToastError(error.response.data.message);
                if('errors' in error.response.data) {
                    this.errors = error.response.data.errors;
                } else {
                    this.singleError = error.response.data.message;
                }
                // console.log('error xxx: ', this.errors)
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>

<style></style>