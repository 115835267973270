<template>
    <div class="modal fade" tabindex="-1" aria-hidden="true" ref="modalRef">
        <div class="modal-dialog">
            <div class="modal-content">
                <div v-if="title!=''" class="modal-header">
                    <h5 class="modal-title text-secondary">{{ title }}</h5>
                    <!-- <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button> -->
                </div>
                <div class="modal-body text-secondary">
                    <slot />
                </div>
                <div class="modal-footer">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { Modal } from 'bootstrap';

export default {
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const modalRef = ref(null);
        let modal;

        onMounted(() => {
            modal = new Modal(modalRef.value);
        });

        watch(() => props.modelValue, (modelValue) => {
            // console.log('modelValue: ' + modelValue)
            if (modelValue) {
                modal.show();
            } else {
                modal.hide();
            }
        });

        function closeModal() {
            // modal.hide();
            props.modelValue = false;
            emit('update:modelValue', false);
        }

        return {
            modalRef,
            closeModal
        };
    }
};
</script>
